import { Component } from '@angular/core';

@Component({
  selector: 'hyb-empty',
  template: ``
})
export class EmptyComponent {

  constructor(
    // @Inject(AUTH_SERVICE) private authService: any
  ) {}

}
