import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone} from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'hyb-sankey-diagram',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sankey-diagram.component.html',
  styleUrls: ['./sankey-diagram.component.scss']
})
export class SankeyDiagramComponent implements AfterViewInit {
  private svg: any;
  private width: any;
  private height: any;
  private color: any;
  private format: any;
  private transparentNodes: any = [];
  private errorMessage: boolean = false;

  @Input() links: Array<any> = [];
  @Input() nodes: Array<any> = [];
  @Input() remove: Array<any> = [];
  @Input() diagramID: string = '';

  constructor(private ngZone: NgZone,
              private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.transparentNodes = this.remove.map((segment) => segment.segment_id);
    this.cdr.detach();
    this.createSVG();
    this.drawChart();
    this.cdr.checkNoChanges();
  }

  private createSVG(): void {
    this.ngZone.runOutsideAngular(() => {
      const margin: any = {top: 10, right: 10, bottom: 10, left: 10};
      this.width = 1000 - margin.left - margin.right;
      this.height = 500 - margin.top - margin.bottom;

      const formatNumber: any = d3.format(',.0f');
      this.format = (d) => formatNumber(d);
      this.color = d3.scale.category20();

      this.svg = d3.select('#' + this.diagramID).append('svg')
        .attr('width', this.width + margin.left + margin.right)
        .attr('height', this.height + margin.top + margin.bottom)
        .append('g')
        .attr('transform',
          'translate(' + margin.left + ',' + margin.top + ')');
    });
  }

  private drawChart(): void {
    const self: any = this;

    this.ngZone.runOutsideAngular(() => {
      const sankey: any = (window as any).d3.sankey()
        .nodeWidth(15)
        .nodePadding(10)
        .size([this.width, this.height]);

      const path: any = sankey.link();

      try {
        sankey
          .nodes(this.nodes)
          .links(this.links)
          .layout(32);
      } catch(e) {
        this.errorMessage = true;
        this.cdr.detectChanges();
        return;
      }

      const link: any = this.svg.append('g').selectAll('.link')
        .data(this.links)
        .enter().append('path')
        .attr('class', 'link')
        .attr('d', path)
        .attr('fill', 'none')
        .attr('stroke-opacity', 0.3)
        .style('stroke-width', (d) => Math.max(1, d.dy))
        .sort((a: any, b: any) => b.dy - a.dy);

      link.append('title')
        .text((d: any) => d.source.name + ' → ' +
          d.target.name + '\n' + self.format(d.value));

      const node: any = this.svg.append('g').selectAll('.node')
        .data(this.nodes)
        .enter().append('g')
        .attr('class', 'node')
        .attr('transform', (d: any) => 'translate(' + d.x + ',' + d.y + ')')
        .call(d3.behavior.drag()
          .origin((d: any) => d)
          .on('dragstart', function(): void {
            this.parentNode.appendChild(this); })
          .on('drag', dragmove));

      node.append('rect')
        .attr('height', (d) => d.dy )
        .attr('width', sankey.nodeWidth())
        .style('fill', (d) => {
          return d.color = this.transparentNodes.includes(Number(d.id)) ? 'transparent' : this.color(d.name.replace(/ .*/, '')); })
        .style(('stroke' as any), (d: any): any => this.transparentNodes.includes(Number(d.id)) ? '#DCDCDC' : d3.rgb(d.color).darker(2))
        .append('title')
        .text((d) => d.id + ': ' + d.name + '\n' + this.format(d.value));

      node.append('text')
        .attr('x', -6)
        .attr('y', (d) => d.dy / getRandomInt(1, 9))
        .attr('dy', '.35em')
        .attr('text-anchor', 'end')
        .attr('transform', null)
        .text((d: any) => this.transparentNodes.includes(Number(d.id)) ? '' : d.name)
        .filter((d) => d.x < this.width / 2)
        .attr('x', 6 + sankey.nodeWidth())
        .attr('text-anchor', 'start');

      function dragmove(d: any): void {
        d3.select(this).attr('transform',
          'translate(' + (
            d.x = Math.max(0, Math.min(self.width - d.dx, (d3.event as any).x))
          ) + ',' + (
            d.y = Math.max(0, Math.min(self.height - d.dy, (d3.event as any).y))
          ) + ')');
        sankey.relayout();
        link.attr('d', path);
      }

      function getRandomInt(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
      }
    });
  }
}
