import { Component, OnInit } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';

@Component({
  selector: 'hyb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(public upgrade: UpgradeModule) {

  }

  ngOnInit(): void {
    this.upgrade.bootstrap(document.body, ['application'], {strictDi: true});
  }
}
