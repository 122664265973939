import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'NumberIsAvailable'
})
export class NumberIsAvailablePipe implements PipeTransform {
  transform(input: number, param?: string): string {
    if(input === null || input === undefined) {
      return 'n/a';
    } else {
      return formatNumber(input, 'en-US');
    }
  }
}
