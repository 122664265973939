import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConstService } from '../../shared/const.service';
import { NotifyService } from '../../shared/notify.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private exeptionUrl: any = [
    '../public/json/locale/ru.json',
    '../public/json/locale/en.json',
    '/api/v1/user/current/',
    '/api/v1/user/logout/',
    '/p/user/register/',
    '/p/user/login/',
    '/p/user/confirm/',
    '/p/user/password/reset/',
    '/p/user/password/confirm/'
  ];
  private exeptionStartWithUrl: any = [
    '/api/v1/partner/segments/',
    '/api/v1/user/affinity/settings/'
  ];
  private startWithUrl: any = [
    '/api/v1/user/',
    '/api/v1/partner/'
  ];
  constructor(private notifyService: NotifyService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accountId: any = localStorage.getItem('selected_account_id');
    if (!this.exeptionUrl.includes(req.url) && this.startWithUrl.some((substr: any) => req.url.startsWith(substr))
          && !this.exeptionStartWithUrl.some((substr: any) => req.url.startsWith(substr)) && accountId) {
      let origString = [];
      let stringToAdd = `/account/${accountId}`;
      let newUrl = req.url;

      if (req.url.startsWith('/api/v1/user/journey/') || req.url.startsWith('/api/v1/user/affinity/')) {
        newUrl = newUrl.replace('/user', `/account/${accountId}`);
      } else {
        if (req.url.startsWith('/api/v1/user/')) {
          newUrl = newUrl.replace('user', 'partner');
        }
        let indexPosition = 15;
        origString = newUrl.split('');
        origString.splice(indexPosition, 0, stringToAdd);
        newUrl = origString.join('');
      }
      req = req.clone({url: newUrl});
    }
    req = req.clone({
      headers: req.headers.set('Accept-Language', localStorage.getItem('langId') || 'ru')
    });
    return next.handle(req).pipe(
      catchError((err, caught) => this.onCatch(err, caught))
    );
  }

  private onCatch(resp: HttpErrorResponse, caught: Observable<any>): Observable<any> {
    const err: any = resp.error;
    if(err && err.data && err.data.error_code === 'not_authenticated') {
      this.notifyService.sendNotification(ConstService.AUTH_EVENTS.notAuthorized);
    }
    if(err && err.error_code === 'not_authenticated') {
      this.notifyService.sendNotification(ConstService.AUTH_EVENTS.notAuthorized);
    }
    return throwError(resp);
  }

  private globalErrorHandler(): any {
    const self: any = this;
    return function<T>(source: Observable<T>): Observable<T> {
      return new Observable(subscriber => {
        return source.subscribe({
          next(value: any): void {
            subscriber.next(value);
          },
          error(error: any): void {
            const lastDestination: any = self.getDestination(subscriber);
            if (!lastDestination._error) {
              alert(self.getErrorMessage(error));
            }
            subscriber.error(error);
          },
          complete(): void {
            subscriber.complete();
          }
        });
      });
    }
  }

  private getDestination(subscriber: any): any {
    return (subscriber.destination.destination) ? this.getDestination(subscriber.destination) : subscriber.destination;
  }

  private getErrorMessage(error: any): string {
    let message: string = '';
    const errorResponse: any = error.error.data.error_message;
    for(const item in errorResponse) {
      if(errorResponse.hasOwnProperty(item)) {
        message = errorResponse[item];
      }
    }
    return message;
  }
}
