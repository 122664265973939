import { InjectionToken } from '@angular/core';
import { Provider } from '@angular/core/src/di/provider';

export function stateFactory(i: any): any {
  return i.get('$state');
}
export const $steteToken: InjectionToken<string> = new InjectionToken<string>('$steteToken');
export const angularStateServiceProvider: Provider = {
  provide: $steteToken,
  useFactory: stateFactory,
  deps: ['$injector']
};

export function notificationServiceFactory(i: any): any {
  return i.get('Notification');
}

export const notificationServiceProvider: Provider = {
  provide: 'Notification',
  useFactory: notificationServiceFactory,
  deps: ['$injector']
};
