import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import { AudiencesService } from '../../core/services/audiences.service';
import {UserMarketplaceService} from '../../core/services/user-marketplace.service';
import {AuthService} from '../../core/services/auth.service';
import {forkJoin} from 'rxjs';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {uiNotification} from 'angular';
import {SharingService} from '../../core/services/sharing.service';
import {filter} from 'rxjs/operators';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {ModalDeletePlatformComponent} from './delete-platform.modal/delete-platform.modal.component';
import {Audience, AudienceSearch} from '../../../app-js/audiences/shared/constructor.type';

@Component({
  selector: 'hyb-account.component',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public form: FormGroup;
  public preloader: boolean = true;
  public textLoc: any = {};
  public userInfo: any = {};
  public tariffs: any = [];
  public userTariff: any = {};
  public defaultPlatforms = [];
  public platformList: any = [];
  public searchPlatformList: any = [];
  public selectedPlatformList: any = [];
  public errorPlatformList: any = [];
  public showPlan1: any = false;
  public showPlan2: any = false;
  public showPlan3: any = false;
  public recurrentPlan1: any = false;
  public recurrentPlan2: any = false;
  public recurrentPlan3: any = false;
  public message = '';
  public platform: any = {};
  public changeDetected = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              @Inject('Notification') private Notification: uiNotification.INotificationService,
              private audiencesService: AudiencesService,
              private sharingService: SharingService,
              private userMarketplaceService: UserMarketplaceService,
              private authService: AuthService,
              private fb: FormBuilder) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
  }

  ngOnInit(): void {
    this.authService.userTariff$.pipe(filter((tariff: any) => tariff)).subscribe((tariff: any) => {
      this.userTariff = tariff;
    });

    this.userInfo = this.authService.user;
    this.form = this.fb.group({
      name: [this.userInfo.name, Validators.required],
      email: [{value: this.userInfo.email, disabled: true}],
      phone_number: [this.userInfo.phone_number, [Validators.pattern(/^\+?[78][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/)]],
      company: [this.userInfo.company, Validators.required],
      url: [this.userInfo.url, [Validators.pattern(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}/)]]
    });

    this.form.valueChanges.subscribe((test) => {
      this.changeDetected = true;
    });

    forkJoin([
      this.authService.getUserAccount(),
      this.authService.getTariffs(),
      this.sharingService.getPlatformList()
    ]).subscribe((results: any) => {
      this.tariffs = results[1].data;
      this.defaultPlatforms = results[2].data;
      this.platformList = this.defaultPlatforms.reduce((acc, value) => acc.concat(value.products), []);
      if (results[0].data.selected_platforms.length) {
        this.selectedPlatformList = results[0].data.selected_platforms.map((systemName) => {
          let selectedPlatform = this.platformList.find((platform) => platform.system_name === systemName);
          if (!selectedPlatform) {
            selectedPlatform = {
              system_name: systemName,
              name: systemName
            }
          }
          return selectedPlatform;
        });
        this.errorPlatformList = results[0].data.selected_platforms
          .filter((systemName) => !this.platformList.find((platform) => platform.system_name === systemName));
        this.filterPlatformList(results[0].data.selected_platforms);
      }
      this.getSearchData('');
      this.preloader = false;
    }, () => {
      this.preloader = false;
    });
  }

  public isFormValid(): boolean {
    return this.form.valid;
  }

  public checkActivePlan(plan: any): boolean {
    return this.userTariff.plan && plan && plan.plan_id === this.userTariff.plan.plan_id;
  }

  public changeRecurrent(): void {
    this.authService.changeRecurrent().subscribe(() => {
      this.authService.changeUserTariff();
    });
  }

  public changeTariff(id: any, recurrent: any): void {
    const self = this;
    this.authService.getPaymentOptions({plan_id: id, recurrent}).subscribe((options: any) => {
      var widget = new (window as any).cp.CloudPayments({
        yandexPaySupport: false,
        applePaySupport: false,
        googlePaySupport: false,
        masterPassSupport: false,
        tinkoffInstallmentSupport: false
      });
      widget.pay('charge', // или 'charge'
        options.data,
        {
          onSuccess: function (options) { // success
            self.authService.changeUserTariff();
            self.Notification.success(self.textLoc.paySuccess);
          },
          onFail: function (reason, options) { // fail
          },
          onComplete: function (paymentResult, options) {

          }
        }
      )
    });
  }

  public selectPlatform(): void {
    if (!this.userTariff.type) {
      return;
    }
    this.selectedPlatformList.push(this.platform);
    const platforms: any = this.selectedPlatformList.map((platform) => platform.system_name);
    this.authService.editUserAccount({selected_platforms: platforms}).subscribe((result) => {
      const message = result.data.message || this.platform.name + ' ' + this.textLoc.addDSP;
      this.Notification.success(message);
      this.filterPlatformList(platforms);
      this.sharingService.update();
    });
  }

  public checkActiveSharing(platform: any, index: any): void {
    if (!this.userTariff.type) {
      return;
    }
    this.sharingService.getSharings().then((externals: any) => {
      if (externals.length && externals.some((sharing) => sharing.product_name === platform.name && sharing.is_active)) {
        const dialogRef: MatDialogRef<ModalDeletePlatformComponent> = this.dialog.open(ModalDeletePlatformComponent, {
          width: '350px',
        });
        dialogRef.afterClosed().subscribe((data: any) => {
          if(!data) { return; }
          this.deletePlatform(platform, index);
        });
      } else {
        this.deletePlatform(platform, index);
      }
    });
  }

  public deletePlatform(platform: any, index: any): void {
    this.selectedPlatformList.splice(index, 1);
    const platforms: any = this.selectedPlatformList.map((platform) => platform.system_name);
    this.authService.editUserAccount({selected_platforms: platforms}).subscribe((result) => {
      const message = result.data.message || platform.name + ' ' + this.textLoc.deleteDSP;
      this.Notification.success(message);
      this.filterPlatformList(platforms);
      this.sharingService.update();
    });
  }

  public edit(): void {
    if (this.form.invalid || !this.changeDetected) {
      return;
    }
    const params = {
      name: this.form.value.name,
      phone_number: this.form.value.phone_number,
      company: this.form.value.company,
      url: this.form.value.url
    };
    this.message = '';
    this.authService.editUser(params).subscribe((result) => {
      this.Notification.success(this.textLoc.userEditSuccess);
      this.changeDetected = false;
    }, (error) => {
      if(error.error.data && error.error.data.error_message) {
        for(const item in error.error.data.error_message) {
          if(error.error.data.error_message.hasOwnProperty(item)) {
            this.message += error.error.data.error_message[item][0] + ' ';
          }
        }
      }
    });
  }

  public getSearchData(search: string): AudienceSearch[] {
    this.searchPlatformList = [];
    if(!search) {
      this.searchPlatformList = this.platformList;
      return;
    }

    this.searchPlatformList = this.platformList.filter((platform) => {
      return platform.name.toLowerCase().includes(search);
    });
  }

  public addSpaceToSearch(element: any): void {
    element.value = element.value + ' ';
  }

  private filterPlatformList(selectedPlatforms): void {
    this.platformList = this.defaultPlatforms.reduce((acc, value) => acc.concat(value.products), []).filter((platform) => !selectedPlatforms.includes(platform.system_name));
    this.searchPlatformList = this.searchPlatformList.filter((platform) => !selectedPlatforms.includes(platform.system_name));
  }

  private getErrorMessage(error: any): string {
    let message: string = '';
    const errorResponse: any = error.error.data.error_message;
    for(const item in errorResponse) {
      if(errorResponse.hasOwnProperty(item)) {
        message = errorResponse[item][0];
      }
    }
    return message;
  }
}
