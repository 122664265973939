import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, prop: string): any[] {
    if(!items) { return []; }
    if(!searchText) { return items; }
    searchText = searchText.toLowerCase();
    return items.filter( (it: any) => {
      if(prop) {
        return it[prop].toLowerCase().includes(searchText);
      } else {
        return it.toLowerCase().includes(searchText);
      }
    });
  }
}
