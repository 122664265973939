import { Component } from '@angular/core';
import * as moment from 'moment';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'hyb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public textLoc: any = {};
  constructor(public authService: AuthService) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
  }

  public getCurrentYear(): string {
    return moment().format('YYYY');
  }

  public getUser(): any {
    return this.authService.user;
  }
}
