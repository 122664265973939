export enum GeoFeatureClass {
  City = 'P',
  Village = 'A',
  Country = '0'
}

export interface GeoData {
  country_code: string;
  feature_class: GeoFeatureClass;
  geoname_id: number;
  name: string;
  name_ru: string;
  population: number;
}
