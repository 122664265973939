import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {AuthService} from '../../../core/services/auth.service';

@Component({
  templateUrl: './settings.modal.component.html',
  styleUrls: [ './settings.modal.component.scss' ]
})
export class ModalSettingsComponent implements OnInit {
  private uniqsMin: number = 0;
  private removedSegments: any[] = [];
  private audienceList: any = [];
  public textLoc: any = {};
  public searchData: any = [];

  constructor(public dialogRef: MatDialogRef<ModalSettingsComponent>,
              @Inject(MAT_DIALOG_DATA) public resolve: any,
              public authService: AuthService) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
  }

  ngOnInit(): void {
    this.audienceList = this.resolve.audienceList;
    if (this.resolve.removed_segments) {
      this.removedSegments = this.audienceList.filter((audience) => this.resolve.removed_segments.includes(audience.segment_id));
    }
    if (this.resolve.uniqs_min) {
      this.uniqsMin = this.resolve.uniqs_min;
    }
    this.getSearchData('');
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public addSpaceToSearch(element: any): void {
    element.value = element.value + ' ';
  }

  public addSettings(): void {
    const data: any = {
      removed_segments: this.removedSegments.map((segment) => segment.segment_id),
      uniqs_min: this.uniqsMin
    };
    this.dialogRef.close(data);
  }

  public deleteSegment(index: number): void {
    this.removedSegments.splice(index, 1);
  }

  public selectSegment(event: any, search: string): void {
    this.removedSegments.push(event.value);
    this.getSearchData(search);
  }

  public getSearchData(search: string): void {
    const search_results_limit: number = 50;
    const matcher: RegExp = new RegExp(search, 'i');
    this.searchData.length = 0;
    let segment: any;
    for(let i: number = 0; i < this.audienceList.length && this.searchData.length < search_results_limit; i++) {
      segment = this.audienceList[i];
      if((segment.segment_name.match(matcher) || String(segment.segment_id).match(matcher)) || !search) {
        let isSelected: boolean = false;
        for(let j: number = 0; j < this.removedSegments.length; j++) {
          if(this.removedSegments[j].segment_id === segment.segment_id) {
            isSelected = true;
            break;
          }
        }
        if(isSelected) {
          continue;
        }
        this.searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          index: i
        });
      }
    }
  }
}
