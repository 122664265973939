import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import {AuthService} from '../../../core/services/auth.service';

@Component({
  templateUrl: './delete-platform.modal.component.html'
})
export class ModalDeletePlatformComponent {
  public textLoc: any = {};
  constructor(public dialogRef: MatDialogRef<ModalDeletePlatformComponent>,
              private authService: AuthService) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.dialogRef.close( true );
  }
}
