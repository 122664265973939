import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { uiNotification } from 'angular';
import { finalize } from 'rxjs/operators';

// import * as d3 from '(window as any).d3';
import { AuthService } from '../../core/services/auth.service';
import { AudiencesService } from '../../core/services/audiences.service';
import { MarketplaceService } from '../../core/services/marketplace.service';
import { PixelService } from '../../core/services/pixel.service';

// declare const dagreD3: any;
declare const graphlibDot: any;

@Component({
  selector: 'hyb-audience-edit',
  templateUrl: './audience-edit.component.html',
  styleUrls: ['./audience-edit.component.scss']
})
export class AudienceEditComponent implements OnInit {

  // todo: (prokopenko) move sunburst to another place
  // sunburst
  private sunburstWidth: number = 1062;
  private sunburstHeight: number = 500;
  private radius: number = Math.min(this.sunburstWidth, this.sunburstHeight) / 2;
  // breadcrumb dimensions: width, height, spacing, width of tip/tail.
  private b: any = {
    w: 96, h: 30, s: 3, t: 10
  };
  // total size of all segments; we set this later, after loading the data.
  private totalSize: number = 0;
  private vis: any;
  private partition: any;
  private arc: any;
  // private renderer: any = new dagreD3.render();
  private g: any;
  private lookalike_model_dot: any;

  public modeEdit: boolean = false;
  public preloader: boolean = true;
  public submitDisable: boolean = false;
  public message: string = '';
  public modalTitle: string = 'Create Audience';
  public pixel: any = {};
  public isGroup: boolean = false;
  public jsonTree: any = null;
  public elementSelected: boolean = false;
  public excludableSegments: any[] = [];
  public lookalikeEstimation: any = {};
  public lookalike: any = {
    newPercent: 0
  };
  public select: any = {
    excludeSegments: []
  };
  public sunburstInfo: any = {
    segment: null,
    value: null,
    samples: 0,
    show: false
  };
  public treeSelectedNode: any = {
    segment: null,
    value: null,
    samples: 0,
    show: false
  };
  public searchData: any[] = [];
  public searchFilter: string = '';
  public segmentId: any;
  public type: any;
  public size: number = 0;
  public pixelId: any;
  public pixelPID: any;
  public lookalikePercent: any;
  public lookalikeLifeTime: any = '-';
  public publicAudiencesPreloader: any;
  public lookalikeParent: any;
  public audience: FormGroup;
  public intensity: number = 1;
  public expiredTime: any = {
    min: 0,
    max: 90
  };

  public manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  public textLoc: any = {};

  constructor(private router: Router,
              private route: ActivatedRoute,
              private pixelService: PixelService,
              private audiencesService: AudiencesService,
              private authService: AuthService,
              @Inject('Notification') private Notification: uiNotification.INotificationService,
              private marketplaceService: MarketplaceService,
              private formBuilder: FormBuilder) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
      this.modalTitle = text.createAudience;
    });
  }

  ngOnInit(): void {
    if(this.route.snapshot.paramMap.get('segmentId')) {
      this.modeEdit = true;
      this.segmentId = Number(this.route.snapshot.paramMap.get('segmentId'));
      this.clearAudience();

      this.audiencesService.getAudienceById(this.segmentId).subscribe((response: any) => {
        function secondsToDays(seconds: any): number {
          return seconds === null ? null : seconds / 60 / 60 / 24;
        }

        const segment: any = response;
        this.size = segment.size;
        segment.account =this.authService.getSelectedAccount().account_id;

        this.type = segment.type;
        this.modalTitle = this.textLoc.edit + ' \'' + segment.segment_name + '\'';
        if(segment.segment_id) {
          this.modalTitle = this.modalTitle + ' (' + segment.segment_id + ')';
        }
        this.pixelId = segment.pixel_id;
        this.pixelPID = segment.pixel_pid;
        this.isGroup = !!segment.is_empty;
        if(this.type === 'look-alike') {
          segment.lookalike_stat && (this.lookalikeEstimation = segment.lookalike_stat.reduce((result: any, item: any) => {
            result[item.percent] = item.size;
            return result;
          }, {}));
          this.lookalikePercent = segment.lookalike_percent;
          this.lookalikeLifeTime = this.getLifeTime(segment);
          this.lookalike.newPercent = segment.lookalike_percent;
          if(segment.lookalike_excluded) {
            this.publicAudiencesPreloader = true;
            this.marketplaceService.getAudienceList().then((publicSegments: any) => {
              this.excludableSegments = publicSegments;
              if(segment.lookalike_model_dot) {
                this.lookalike_model_dot = segment.lookalike_model_dot;
              }
              this.audiencesService.getAudienceList().then((audiences: any) => {
                this.addExcludableAudiences(audiences);
                segment.lookalike_excluded.forEach((segment_id: any) => {
                  for(let i: number = 0; i < this.excludableSegments.length; i++) {
                    if(this.excludableSegments[i].segment_id === segment_id) {
                      this.select.excludeSegments.push(this.excludableSegments[i]);
                      return;
                    }
                  }
                  this.select.excludeSegments.push({
                    segment_id: segment_id,
                    segment_name: '<name hidden>'
                  });
                });
                this.getSearchData('');
                this.publicAudiencesPreloader = false;
                setTimeout(() => this.manualRefresh.emit(), 0);
                this.audience.updateValueAndValidity();
                if(segment.lookalike_model_dot) {
                  try {
                    this.constructSunburst(segment.lookalike_model_dot);
                  } catch(error) {
                    console.log(error);
                  }
                }
              });
            });
          }
          this.audiencesService.getAudienceList().then(() => {
            this.lookalikeParent = segment.lookalike_parent_id ?
              this.audiencesService.getAudience(segment.lookalike_parent_id) : {};
            this.preloader = false;
            if(!segment.lookalike_excluded.length) {
              setTimeout(() => this.manualRefresh.emit(), 0);
              this.audience.updateValueAndValidity();
            }
          });
        } else {
          this.pixelService.getPixel(segment.pixel_pid).then((pixel: any) => {
            this.pixel = pixel || {};
            pixel && this.audience.get('pixelPid').setValue(pixel.pid);
            this.preloader = false;
            setTimeout(() => this.manualRefresh.emit(), 0);
          });
        }

        const domainsFormValue: FormArray = <FormArray>this.audience.get('domains.value');

        this.expiredTime.min = (segment.expired_min === null || segment.expired_min === undefined)
          ? 0 : secondsToDays(segment.expired_min);
        this.expiredTime.max = (segment.expired_max === null || segment.expired_max === undefined)
          ? 90 : secondsToDays(segment.expired_max);

        this.audience.get('name').setValue(segment.segment_name);
        this.audience.get('account').setValue(segment.account);
        this.audience.get('is_total_audience').setValue(segment.is_total_audience);
        this.intensity = segment.intensity_min === null ? 1 : segment.intensity_min;

        ((segment.urls && segment.urls.length) || (segment.keywords_shuffle && segment.keywords_shuffle.length)
          || (segment.urls_startwith && segment.urls_startwith.length)) && this.clearUrlsFormArray();

        if(segment.stop_domains && segment.stop_domains.length) {
          this.audience.get('domains.type').setValue('exclude');
          this.clearDomainsFormArray();
          segment.stop_domains.forEach((domain: string) => domainsFormValue.push(this.getDomainFormControl(domain)));
        }

        if(segment.domains && segment.domains.length) {
          this.audience.get('domains.type').setValue('include');
          this.clearDomainsFormArray();
          segment.domains.forEach((domain: string) => domainsFormValue.push(this.formBuilder.control({value: domain, disabled: this.prohibitAudienceEdit()})));
        }

        !!segment.urls && segment.urls.forEach((url: any) => {
          (this.audience.get('urls') as FormArray).push(this.formBuilder.group({
            type: 'equals',
            value: this.formBuilder.array([this.getUrlFormControl(url, true)])
          }));
        });

        !!segment.urls_startwith && segment.urls_startwith.forEach((url: any) => {
          (this.audience.get('urls') as FormArray).push(this.formBuilder.group({
            type: 'startswith',
            value: this.formBuilder.array([this.getUrlFormControl(url, true)])
          }));
        });

        !!segment.keywords_shuffle && segment.keywords_shuffle.forEach((rule: any) => {
          const formArray: FormControl[] = rule.map((item: string) => this.getUrlFormControl(item));
          (this.audience.get('urls') as FormArray).push(this.formBuilder.group({
            type: 'contains',
            value: this.formBuilder.array(formArray)
          }));
        });
      }, () => {
        this.preloader = false;
      });
    } else {
      this.pixelId = Number(this.route.snapshot.paramMap.get('pixelId'));
      this.type = 'normal';
      this.clearAudience();

      this.pixelService.getPixel(this.pixelId, 'id').then((pixel: any) => {
        pixel.account = this.authService.getSelectedAccount().account_id
        this.pixel = pixel || {};
        pixel && this.audience.get('pixelPid').setValue(pixel.pid);
        this.preloader = false;
        this.manualRefresh.emit();
      });
    }
  }

  public getBackLink(): string {
    if(this.modeEdit) {
      return '/audiences';
    } else {
      return '/pixel/list';
    }
  }

  public getEstimation(): any {
    const percent: any = this.lookalike.newPercent;
    if(this.lookalikeEstimation.hasOwnProperty(percent)) {
      return this.lookalikeEstimation[percent];
    } else {
      return null;
    }
  }

  public getLifeTime(segment: any): any {
    if (!segment.expire_days || segment.expire_days < 0) {
      return '-';
    }
    const expireDate: any = new Date(segment.created_at);
    const todayDate: any = new Date();

    expireDate.setDate(expireDate.getDate() + segment.expire_days);

    const timeDiff: number = Math.abs(expireDate.getTime() - todayDate.getTime());
    if (timeDiff < 0) {
      return this.textLoc.expired;
    }

    return Math.ceil(timeDiff / (1000 * 3600 * 24)) + ' days';
  }

  public prohibitAudienceEdit(): boolean {
    return this.modeEdit && this.size > 0;
  }

  public createAudience(): void {
    this.submitDisable = true;
    const data: any = this.getValidData();
    this.audiencesService.saveAudience(data).pipe(finalize(() => this.submitDisable = false))
      .subscribe((response: any) => {
        this.audiencesService.addAudience(response);
        this.Notification.success(this.textLoc.audienceCreated);
        this.message = '';
        this.clearAudience();
      }, (error: any) => {
        this.message = error.error.data.error_message[0];
      });
  }

  public editAudience(): void {
    this.submitDisable = true;
    let data: any = this.getValidData();
    data = {
      id: this.segmentId,
      account: data.account,
      pixel_pid: data.pixel_pid,
      segment_name: data.segment_name,
      partners_only: data.partners_only,
      expired_max: data.expired_max,
      expired_min: data.expired_min,
      intensity_min: data.intensity_min,
      urls: data.urls,
      urls_startwith: data.urls_startwith,
      keywords_shuffle: data.keywords_shuffle,
      stop_domains: data.stop_domains,
      domains: data.domains
    };
    if(this.lookalike.newPercent !== undefined) {
      data.lookalike_percent = this.lookalike.newPercent;
      data.lookalike_excluded = this.select.excludeSegments.map((segment: any) => {
        return segment.segment_id;
      });
    }
    this.audiencesService.patchAudience(data).pipe(finalize(() => this.submitDisable = false))
      .subscribe((response: any) => {
        this.Notification.success(this.textLoc.audienceChanged);
        this.message = '';
        this.audiencesService.getAudienceList().then(() => {
          this.audiencesService.editAudience(this.segmentId, response.segment_name, response.lookalike_percent);
          this.lookalikePercent = response.lookalike_percent;
        });
      }, (error: any) => {
        this.message = error.error.data.error_message[0];
      });
  }

  public addDomainsRule(): void {
    (this.audience.get('domains.value') as FormArray).push(this.getDomainFormControl());
  }

  public addUrlsRule(): void {
    (this.audience.get('urls') as FormArray).push(this.formBuilder.group({
      type: 'equals',
      value: this.formBuilder.array([this.getUrlFormControl('', true)])
    }));
  }

  public removeDomainsRule(index: number): void {
    const domainsFormArray: FormArray = <FormArray>this.audience.get('domains.value');
    domainsFormArray.removeAt(index);
    domainsFormArray.length === 0 && this.addDomainsRule();
  }

  public removeUrlsRule(ruleIndex: number, wordIndex: number): void {
    if(wordIndex === undefined) {
      (this.audience.get('urls') as FormArray).removeAt(ruleIndex);
    } else {
      (this.audience.get(['urls', ruleIndex, 'value']) as FormArray).removeAt(wordIndex);
      (this.audience.get(['urls', ruleIndex, 'value']) as FormArray).length === 0
        && (this.audience.get('urls') as FormArray).removeAt(ruleIndex);
    }
    (this.audience.get('urls') as FormArray).length === 0 && this.addUrlsRule();
  }

  public deleteExcludeLookalike(index: number): void {
    this.select.excludeSegments.splice(index, 1);
  }

  public selectExclude(event: any, search: string): void {
    this.select.excludeSegments.push(event.value);
    this.getSearchData(search);
  }

  public addUrlsWords(index: number): void {
    const urlsFormArray: FormArray = <FormArray>this.audience.get(['urls', index, 'value']);
    urlsFormArray.push(this.getUrlFormControl());
  }

  public selectUrlRuleType(index: number, type: string): void {
    const rule: FormGroup = <FormGroup>this.audience.get(['urls', index]);
    const typeControl: AbstractControl = rule.get('type');
    const valueControl: FormArray = <FormArray>rule.get('value');

    if(typeControl.value !== type) {
      while (valueControl.length !== 0) {
        valueControl.removeAt(0);
      }
      typeControl.setValue(type);
      if(type === 'equals' || type === 'startswith') {
        valueControl.push(this.getUrlFormControl('', true));
      } else {
        valueControl.push(this.getUrlFormControl());
      }
    }
  }

  public haveEditPermission(): boolean {
    return (this.modeEdit ?
      this.authService.getAccountById(this.audience.get('account').value).permission :
      this.authService.getSelectedAccount().permission) === 'edit';
  }

  public haveTotalAudiencePermission(): boolean {
    return !this.modeEdit && this.audience.get('is_total_audience').value && this.pixel.exists_total_audience;
  }

  public edit(): void {
    if(this.modeEdit) {
      this.editAudience();
    } else {
      this.createAudience();
    }
  }

  public getSearchData(search: any): void {
    const search_results_limit: number = 50;
    const matcher: RegExp = new RegExp(search, 'i');
    this.searchData.length = 0;
    let segment: any;
    for(let i: number = 0; i < this.excludableSegments.length && this.searchData.length < search_results_limit; i++) {
      segment = this.excludableSegments[i];
      if((segment.segment_name.match(matcher) || String(segment.segment_id).match(matcher)) || !search) {
        let isSelected: boolean = false;
        for(let j: number = 0; j < this.select.excludeSegments.length; j++) {
          if(this.select.excludeSegments[j].segment_id === segment.segment_id) {
            isSelected = true;
            break;
          }
        }
        if(isSelected) {
          continue;
        }
        this.searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          index: i
        });
      }
    }
  }

  private clearUrlsFormArray(): void {
    const formArray: FormArray = <FormArray>this.audience.get('urls');
    while(formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  private clearDomainsFormArray(): void {
    const formArray: FormArray = <FormArray>this.audience.get('domains.value');
    while(formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  private getDomainFormControl(str?: string): FormControl {
    const value: string = str || '';
    const pattern: RegExp = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
    return this.formBuilder.control({value: value, disabled: this.prohibitAudienceEdit()}, Validators.pattern(pattern));
  }

  private getUrlFormControl(str?: string, equal?: boolean): FormControl {
    const value: string = str || '';
    const pattern: RegExp = (equal) ? new RegExp(/^(ftp|http|https):\/\/[^ "]+$/) : new RegExp(/^[^ "]+$/);
    const control: any = this.formBuilder.control( {value: value, disabled: this.prohibitAudienceEdit()}, Validators.pattern(pattern));
    return control;
  }

  private clearAudience(): void {
    const self: any = this;

    this.intensity = 1;
    this.expiredTime.min = 0;
    this.expiredTime.max = 90;

    this.audience = this.formBuilder.group({
      pixelPid: this.pixel.pid || '',
      account: this.authService.getSelectedAccount().account_id,
      name: ['', Validators.required],
      is_total_audience: [{value: false, disabled: this.modeEdit}],
      domains: this.formBuilder.group({
        type: 'include',
        value: this.formBuilder.array([this.getDomainFormControl()])
      }),
      urls: this.formBuilder.array([
        this.formBuilder.group({
          type: 'equals',
          value: this.formBuilder.array([this.getUrlFormControl()])
        })
      ])
    }, {validator: checkDomainsUrlsRequired()});

    function checkDomainsUrlsRequired(): Function {
      return (group: FormGroup): any => {
        if(group.get('is_total_audience').value || self.type === 'look-alike' || self.isGroup || self.modeEdit) {
          return null;
        }

        const domainsType: string = group.get('domains.type').value;
        const firstUrlFormArray: FormArray = <FormArray>group.get(['urls', 0, 'value', 0]);
        const firstDomainFormArray: FormArray = <FormArray>group.get(['domains', 'value', 0]);

        if(!firstUrlFormArray || !firstDomainFormArray) {
          return null;
        }

        const errorRequieredDomains: boolean = (group.get('domains.value') as FormArray).controls.some((control: FormControl) => {
          return ((domainsType === 'exclude' || !firstUrlFormArray.value) && control.value === '');
        });
        const errorRequiredUrls: boolean = (group.get('urls') as FormArray).controls.some((urlGroup: FormGroup) => {
          return (urlGroup.get('value') as FormArray).controls.some((control: FormControl) => {
            return (domainsType === 'exclude' || !firstDomainFormArray.value) && control.value === '';
          });
        });

        if(errorRequieredDomains || errorRequiredUrls) {
          return {
            errorRequired:
              {valid: false}
          };
        } else {
          return null;
        }
      };
    }
  }

  private cleanArray(actual: FormArray): any {
    const newArray: any = [];
    for(let i: number = 0; i < actual.length; i++) {
      actual.at(i).value && newArray.push(actual.at(i).value);
    }
    return newArray;
  }

  private getValidData(): any {
    const domainsFormArray: FormArray = <FormArray>this.audience.get('domains.value');

    function daysToSeconds(days: any): number {
      return days === null ? null : days * 60 * 60 * 24;
    }

    const data: any = {
      pixel_pid: this.pixel.pid || null,
      segment_name: this.audience.get('name').value,
      account: this.audience.get('account').value,
      urls: [],
      urls_startwith: [],
      keywords_shuffle: [],
      stop_domains: [],
      domains: [],
      partners_only: [this.pixel.pid],
      expired_max: daysToSeconds(this.expiredTime.max),
      expired_min: daysToSeconds(this.expiredTime.min),
      intensity_min: this.intensity,
      is_total_audience: this.audience.get('is_total_audience').value
    };
    (this.type === 'look-alike') && (data.partners_only = []);
    if(data.is_total_audience) {
      return data;
    }
    switch(this.audience.get('domains.type').value) {
      case 'include':
        data.domains = this.cleanArray(domainsFormArray);
        break;
      case 'exclude':
        data.stop_domains = this.cleanArray(domainsFormArray);
        break;
    }
    (this.audience.get('urls') as FormArray).controls.forEach((rule: FormGroup) => {
      const ruleFormArray: FormArray = <FormArray>rule.get('value');

      if(this.cleanArray(ruleFormArray).length === 0) {
        return;
      }
      switch(rule.get('type').value) {
        case 'equals':
          data.urls.push(ruleFormArray.at(0).value);
          break;
        case 'contains':
          data.keywords_shuffle.push(this.cleanArray(ruleFormArray));
          break;
        case 'startswith':
          data.urls_startwith.push(ruleFormArray.at(0).value);
          break;
      }
    });
    return data;
  }

  private addExcludableAudiences(audiences: any): void {
    audiences.forEach((audience: any) => {
      if(audience.is_active && !audience.is_empty && (['normal', 'manual'].indexOf(audience.type) !== -1)) {
        for(let i: number = 0; i < this.excludableSegments.length; i++) {
          if(this.excludableSegments[i].segment_id === audiences.segment_id) {
            return;
          }
        }
        this.excludableSegments.push(audience);
      }
    });
  }

  /*private asyncRender(): void {
    this.renderer((window as any).d3.select('#graph'), this.g);
  }*/

  private colors(node: any): any {
    const index: number = (node.childrenIndex || 0) * 2 + node.depth % 2;
    // if(node.parent && node.parent.children[1] == node) {
    // 	index = 2;
    // }
    // index += node.depth%2;
    // colors.index = (colors.index + 1) % 4 || 0;
    const col: string[] = ['#F7CA5D', '#F08F66', '#8CC663', '#78C8E5'];
    // return col[colors.index];
    // return col[node.id % 4];
    return col[index];
  }

  /**
   * Main function to draw and set up the visualization, once we have the data.
   * @param json
   */
  private createVisualization(json: any): void {
    const self: any = this;

    /**
     * Restore everything to full opacity when moving off the visualization.
     * @param d
     */
    function mouseleave(d: any): void {
      if(self.elementSelected) {
        return;
      }
      // hide the breadcrumb trail
      (window as any).d3.select('#trail')
        .style('visibility', 'hidden');

      // deactivate all segments during transition.
      (window as any).d3.selectAll('.sunburst__chart path').on('mouseover', null);

      // transition each segment to full opacity and then reactivate it.
      (window as any).d3.selectAll('.sunburst__chart path')
        .transition()
        .duration(1000)
        .style('opacity', 1)
        .each('end', function(): void {
          (window as any).d3.select(this).on('mouseover', mouseover);
        });

      self.sunburstInfo.show = false;
      // self.$scope.$apply();
    }

    /**
     * Fade all but the current sequence, and show it in the breadcrumb trail.
     * @param d
     */
    function mouseover(d: any): void {
      if(self.elementSelected) {
        return;
      }
      const percentage: any = (100 * d.value / self.totalSize).toPrecision(3);
      let percentageString: string = percentage + '%';
      if(percentage < 0.1) {
        percentageString = '< 0.1%';
      }
      self.sunburstInfo.segment_name = d.segment_name;
      self.sunburstInfo.name = d.name;
      self.sunburstInfo.gini = Math.floor(parseFloat(d.gini) * 1000000) / 1000000;
      self.sunburstInfo.samples = d.size;
      self.sunburstInfo.similarity = d.similarity;
      self.sunburstInfo.show = true;

      const sequenceArray: any = self.getAncestors(d);
      self.updateBreadcrumbs(sequenceArray, percentageString);

      // fade all the segments.
      (window as any).d3.selectAll('.sunburst__chart path')
        .style('opacity', 0.3);

      // then highlight only those that are an ancestor of the current segment.
      self.vis.selectAll('path')
        .filter((node: any) => {
          return (sequenceArray.indexOf(node) >= 0);
        })
        .style('opacity', 1);
      // self.$scope.$apply();
    }

    // basic setup of page elements.
    this.initializeBreadcrumbTrail();

    // bounding circle underneath the sunburst, to make it easier to detect
    // when the mouse leaves the parent g.
    this.vis.append('svg:circle')
      .attr('r', this.radius)
      .style('opacity', 0);

    // for efficiency, filter nodes to keep only those large enough to see.
    const nodes: any = this.partition.nodes(json)
      .filter((d: any) => {
        return (d.dx > 0.005); // 0.005 radians = 0.29 degrees
      });

    const path: any = this.vis.data([json]).selectAll('path')
      .data(nodes)
      .enter().append('svg:path')
      .attr('display', (d: any) => {
        return d.depth ? null : 'none';
      })
      .attr('d', this.arc)
      .attr('fill-rule', 'evenodd')
      .style('fill', (d: any) => {
        return this.colors(d);
      })
      .style('opacity', 1)
      .on('mouseover', mouseover)
      .on('click', () => {
        this.elementSelected = !this.elementSelected;
      });
    // add the mouseleave handler to the bounding circle.
    (window as any).d3.select('#container').on('mouseleave', mouseleave);

    // get total size of the tree = value of root node from partition.
    this.totalSize = (path.node() as any).__data__.value;
  }

  /**
   * Given a node in a partition layout, return an array of all of its ancestor nodes, highest first, but excluding the root.
   * @param node
   * @returns {any[]}
   */
  private getAncestors(node: any): any {
    const path: any = [];
    let current: any = node;
    while(current.parent) {
      path.unshift(current);
      current = current.parent;
    }
    return path;
  }

  private initializeBreadcrumbTrail(): void {
    // add the svg area.
    const trail: any = (window as any).d3.select('.sunburst__sequence').append('svg:svg')
      .attr('width', this.sunburstWidth)
      .attr('height', 50)
      .attr('id', 'trail');
    // add the label at the end, for the percentage.
    trail.append('svg:text')
      .attr('id', 'endlabel')
      .style('fill', '#000');
  }

  /**
   * Update the breadcrumb trail to show the current sequence and percentage.
   * @param nodeArray
   * @param percentageString
   */
  private updateBreadcrumbs(nodeArray: any, percentageString: any): void {

    const self: any = this;

    /**
     * Generate a string that describes the points of a breadcrumb polygon.
     * @param d
     * @param i
     * @returns {string}
     */
    const breadcrumbPoints: any = (d: any, i: any) => {
      const points: any = [];
      points.push('0,0');
      points.push(self.b.w + ',0');
      points.push(self.b.w + self.b.t + ',' + (self.b.h / 2));
      points.push(self.b.w + ',' + self.b.h);
      points.push('0,' + self.b.h);
      if(i > 0) { // leftmost breadcrumb; don't include 6th vertex.
        points.push(self.b.t + ',' + (self.b.h / 2));
      }
      return points.join(' ');
    };

    const wrap: any = (width: any, padding: any) => {
      return function(): void {
        const selfLocal: any = (window as any).d3.select(this);
        let textLength: number = (selfLocal.node() as any).getComputedTextLength(),
            text: string = selfLocal.text();
        while(textLength > (width - 2 * padding) && text.length > 0) {
          text = text.slice(0, -1);
          selfLocal.text(text + '...');
          textLength = (selfLocal.node() as any).getComputedTextLength();
        }
      };
    };

    const tip: any = ((window as any).d3 as any).tip()
      .attr('class', '(window as any).d3-tip')
      .offset([-13, 0])
      .html((d: any) => {
        return '<span>' + d.segment_name + '</span>';
      });
    (window as any).d3.select('#trail').call(tip);

    // data join; key function combines name and depth (= position in sequence).
    const g: any = (window as any).d3.select('#trail')
      .selectAll('g')
      .data(nodeArray, (d: any) => {
        return d.name + d.depth;
      });

    // add breadcrumb and label for entering nodes.
    const entering: any = g.enter().append('svg:g');

    entering.append('svg:polygon')
      .attr('points', breadcrumbPoints)
      .style('fill', (d: any) => {
        return this.colors(d);
      });

    entering.append('svg:text')
      .attr('x', (this.b.w + this.b.t) / 2)
      .attr('y', this.b.h / 2)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .append('tspan')
      .text((d: any) => {
        return d.segment_name || d.name;
      })
      .each(wrap(this.b.w, 5))
      .on('mouseover', tip.show)
      .on('mouseout', tip.hide);

    // set position for entering and updating nodes.
    g.attr('transform', (d: any, i: any) => {
      return 'translate(' + i * (this.b.w + this.b.s) + ', 0)';
    });

    // remove exiting nodes.
    g.exit().remove();

    // now move and update the percentage at the end.
    (window as any).d3.select('#trail').select('#endlabel')
      .attr('x', (nodeArray.length + 0.5) * (this.b.w + this.b.s))
      .attr('y', this.b.h / 2)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .text(percentageString);

    // make the breadcrumb trail visible, if it's hidden.
    (window as any).d3.select('#trail')
      .style('visibility', '');
  }

  private constructSunburst(model_dot: any): void {
    model_dot = model_dot.split('box').join('rect');
    this.g = graphlibDot.read(model_dot);

    this.jsonTree = {};
    this.g.nodes().forEach((el: any) => {
      if(this.g.inEdges(el).length === 0) {
        this.jsonTree.id = el;
      }
    });
    const maxDepth: number = 9;

    this.vis = (window as any).d3.select('.sunburst__chart').append('svg:svg')
        .attr('width', this.sunburstWidth)
        .attr('height', this.sunburstHeight)
        .append('svg:g')
        .attr('id', 'container')
        .attr('transform', 'translate(' + this.sunburstWidth / 2 + ',' + this.sunburstHeight / 2 + ')');
    this.partition = (window as any).d3.layout.partition()
        .size([2 * Math.PI, this.radius * this.radius])
        .value((d: any): number => {
          return d.size;
        });
    this.arc = (window as any).d3.svg.arc()
        .startAngle((d: any) => {
          return d.x;
        })
        .endAngle((d: any) => {
          return d.x + d.dx;
        })
        .innerRadius((d: any) => {
          return Math.sqrt(d.y);
        })
        .outerRadius((d: any) => {
          return Math.sqrt(d.y + d.dy);
        });

    const getSegmentName: Function = (segment_id: any) => {
      for(let i: number = 0; i < this.excludableSegments.length; i++) {
        if(this.excludableSegments[i].segment_id === Number(segment_id)) {
          return this.excludableSegments[i].segment_name;
        }
      }
      return null;
    };

    const getChilds: Function = (el: any, depth: any) => {
      const out: any = this.g.outEdges(el.id);
      let label: any = this.g.node(el.id).label;
      label = label.split('\\n');
      label.forEach((lab: any) => {
        if(lab.indexOf('samples') !== -1) {
          el.size = parseInt(lab.split(' = ')[1], 10);
        } else if(lab.indexOf('<=') !== -1) {
          el.name = lab.split(' <= ')[0];
        } else if(lab.indexOf('gini') !== -1) {
          el.gini = Math.floor(parseFloat(lab.split(' = ')[1]) * 10000) / 10000;
        } else if(lab.indexOf('value') !== -1) {
          el.modelValue = lab.split(' = ')[1];
        } else if(lab.indexOf('classes') !== -1) {
          el.classesSimilarity = lab.split(' = ')[1];
        }
      });
      if(el.modelValue) {
        let valueIndex: number = 1;
        if(el.classesSimilarity && el.classesSimilarity.indexOf('00000000') !== 1) {
          valueIndex = 0;
        }
        el.similarity = JSON.parse(el.modelValue)[valueIndex];
      }
      if(!(depth > maxDepth || out.length === 0)) {
        el.children = [];
        out.forEach((edge: any) => {
          const neEl: any = {'id': edge.w, 'childrenIndex': el.children.length};
          el.children.push(neEl);
          getChilds(neEl, depth + 1);
        });
      }
      if(!el.name) {
        el.name = '';
      } else {
        el.segment_name = getSegmentName(el.name);
      }
    };

    getChilds(this.jsonTree, 0);
    this.createVisualization(this.jsonTree);
    this.createTree(this.jsonTree);

    /* const svg: any = (window as any).d3.select('#graphContainer'),
      inner: any = svg.select('g');
    // set up zoom support
    const zoom: any = (window as any).d3.behavior.zoom().on('zoom', () => {
      inner.attr('transform', 'translate(' + ((window as any).d3.event as any).translate + ')' +
        'scale(' + ((window as any).d3.event as any).scale + ')');
    });
    svg.call(zoom);

    // render the graphlib object using (window as any).d3.

    // this.asyncRender();
    // setTimeout($scope.asyncRender, 0);

    const initialScale: number = 0.75;
    zoom
      .translate([(Number(svg.attr('width')) - this.g.graph().width * initialScale) / 2, 20])
      .scale(initialScale)
      .event(svg);

    svg.attr('height', this.g.graph().height * initialScale + 40);
    svg.attr('width', '1062px'); */
  }

  // let a      = document.createElement('a');
  // a.href     = 'data:image/svg+xml;utf8,' + unescape($('#graphContainer')[0].outerHTML);
  // a.download = 'plot.svg';
  // a.target   = '_blank';
  // document.body.appendChild(a); a.click(); document.body.removeChild(a);

  private createTree(sourceTree: any): void {

    let svgGroup: any;

    // calculate total nodes, max label length
    let totalNodes: number = 0;
    let maxLabelLength: number = 0;
    // misc. letiables
    let i: number = 0;
    const duration: number = 750;
    let root: any;

    // size of the diagram
    const viewerWidth: number = 1062;
    const viewerHeight: number = 500;

    let click: any;

    let tree: any = (window as any).d3.layout.tree()
      .size([viewerHeight, viewerWidth]);

    // define a (window as any).d3 diagonal projection for use by the node paths later on.
    const diagonal: any = (window as any).d3.svg.diagonal()
      .projection((d: any) => {
        return [d.y, d.x];
      });

    // a recursive helper function for performing some setup by walking through all nodes
    function visit(parent: any, visitFn: any, childrenFn: any): void {
      if(!parent) { return; }

      visitFn(parent);

      const children: any = childrenFn(parent);
      if(children) {
        const count: number = children.length;
        for(let j: number = 0; j < count; j++) {
          visit(children[j], visitFn, childrenFn);
        }
      }
    }

    // call visit function to establish maxLabelLength
    visit(sourceTree, (d: any) => {
      totalNodes++;
      maxLabelLength = Math.max(d.name.length, maxLabelLength);
    }, (d: any) => {
      return d.children && d.children.length > 0 ? d.children : null;
    });

    // sort the tree according to the node names
    function sortTree(): void {
      tree.sort((a: any, b: any) => {
        return b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1;
      });
    }

    // define the zoomListener which calls the zoom function on the "zoom" event constrained within the scaleExtents
    const zoomListener: any = (window as any).d3.behavior.zoom().scaleExtent([0.1, 3]).on('zoom', zoom);

    // define the baseSvg, attaching a class for styling and the zoomListener
    const baseSvg: any = (window as any).d3.select('#tree-container').append('svg')
      .attr('width', viewerWidth)
      .attr('height', viewerHeight)
      .attr('class', 'overlay')
      .call(zoomListener);

    // append a group which holds all nodes and which the zoom Listener can act upon.
    svgGroup = baseSvg.append('g');

    // sort the tree initially incase the JSON isn't in a sorted order.
    // sortTree();

    // define the zoom function for the zoomable tree
    function zoom(): void {
      svgGroup.attr('transform', 'translate(' + ((window as any).d3.event as any).translate + ')scale(' +
        ((window as any).d3.event as any).scale + ')');
    }

    // function to center node when clicked/dropped so node doesn't get lost when collapsing/moving with large amount of children.
    function centerNode(source: any, width?: any, height?: any): void {
      const scale: any = zoomListener.scale();
      let x: any = -source.y0;
      let y: any = -source.x0;
      width !== undefined || (width = viewerWidth / 2);
      height !== undefined || (height = viewerHeight / 2);
      x = x * scale + width;
      y = y * scale + height;
      baseSvg.select('g').transition()
        .duration(duration)
        .attr('transform', 'translate(' + x + ',' + y + ')scale(' + scale + ')');
      zoomListener.scale(scale);
      zoomListener.translate([x, y]);
    }

    // toggle children function
    function toggleChildren(d: any): any {
      if(d.children) {
        d._children = d.children;
        d.children = null;
      } else if(d._children) {
        d.children = d._children;
        d._children = null;
      }
      return d;
    }

    const update: Function = (source: any) => {
      // compute the new height, function counts total children of root node and sets tree height accordingly.
      // this prevents the layout looking squashed when new nodes are made visible or looking sparse when nodes are removed
      // this makes the layout more consistent.
      const levelWidth: any = [1];
      const childCount: any = (level: any, n: any) => {

        if(n.children && n.children.length > 0) {
          if(levelWidth.length <= level + 1) { levelWidth.push(0); }

          levelWidth[level + 1] += n.children.length;
          n.children.forEach((d: any) => {
            childCount(level + 1, d);
          });
        }
      };
      childCount(0, root);
      const newHeight: number = (window as any).d3.max(levelWidth) * 25; // 25 pixels per line
      tree = tree.size([newHeight, viewerWidth]);

      // compute the new tree layout.
      const nodes: any = tree.nodes(root).reverse(),
        links: any = tree.links(nodes);

      // set widths between levels based on maxLabelLength.
      nodes.forEach((d: any) => {
        d.y = (d.depth * (maxLabelLength * 10)); // maxLabelLength * 10px
        // alternatively to keep a fixed scale one can set a fixed depth per level
        // normalize for fixed-depth by commenting out below line
        // d.y = (d.depth * 500); //500px per level.
      });

      // update the nodes…
      const node: any = svgGroup.selectAll('g.node')
        .data(nodes, (d: any) => {
          return d.id || (d.id = ++i);
        });

      const mouseover: Function = (d: any) => {
        this.treeSelectedNode.name = d.name;
        this.treeSelectedNode.gini = Math.floor(parseFloat(d.gini) * 1000000) / 1000000;
        this.treeSelectedNode.samples = d.size;
        this.treeSelectedNode.value = d.modelValue;
        this.treeSelectedNode.similarity = d.similarity;
        this.treeSelectedNode.show = true;
        this.treeSelectedNode.segment_name = d.segment_name;
        // this.$scope.$apply();
        // console.log(d);
      };

      // enter any new nodes at the parent's previous position.
      const nodeEnter: any = node.enter().append('g')
        .attr('class', 'node')
        .attr('transform', (d: any) => {
          return 'translate(' + source.y0 + ',' + source.x0 + ')';
        })
        .on('click', click)
        .on('mouseover', mouseover);

      nodeEnter.append('circle')
        .attr('class', 'nodeCircle')
        .attr('r', 0)
        .style('fill', (d: any) => {
          return d._children ? 'lightsteelblue' : '#fff';
        });

      nodeEnter.append('text')
        .attr('x', (d: any) => {
          return d.children || d._children ? -10 : 10;
        })
        .attr('dy', '.35em')
        .attr('class', 'nodeText')
        .attr('text-anchor', (d: any) => {
          return d.children || d._children ? 'end' : 'start';
        })
        .text((d: any) => {
          return d.name;
        })
        .style('fill-opacity', 0);

      // update the text to reflect whether node has children or not.
      node.select('text')
        .attr('x', (d: any) => {
          return d.children || d._children ? -10 : 10;
        })
        .attr('text-anchor', (d: any) => {
          return d.children || d._children ? 'end' : 'start';
        })
        .text((d: any) => {
          return d.name;
        });

      // change the circle fill depending on whether it has children and is collapsed
      node.select('circle.nodeCircle')
        .attr('r', 4.5)
        .style('fill', (d: any) => {
          return d._children ? 'lightsteelblue' : '#fff';
        });

      // transition nodes to their new position.
      const nodeUpdate: any = node.transition()
        .duration(duration)
        .attr('transform', (d: any) => {
          return 'translate(' + d.y + ',' + d.x + ')';
        });

      // fade the text in
      nodeUpdate.select('text')
        .style('fill-opacity', 1);

      // transition exiting nodes to the parent's new position.
      const nodeExit: any = node.exit().transition()
        .duration(duration)
        .attr('transform', (d: any) => {
          return 'translate(' + source.y + ',' + source.x + ')';
        })
        .remove();

      nodeExit.select('circle')
        .attr('r', 0);

      nodeExit.select('text')
        .style('fill-opacity', 0);

      // update the links…
      const link: any = svgGroup.selectAll('path.link')
        .data(links, (d: any) => {
          return d.target.id;
        });

      // enter any new links at the parent's previous position.
      link.enter().insert('path', 'g')
        .attr('class', 'link')
        .attr('d', (d: any) => {
          const o: any = {
            x: source.x0,
            y: source.y0
          };
          return diagonal({
            source: o,
            target: o
          });
        });

      // transition links to their new position.
      link.transition()
        .duration(duration)
        .attr('d', diagonal);

      // transition exiting nodes to the parent's new position.
      link.exit().transition()
        .duration(duration)
        .attr('d', (d: any) => {
          const o: any = {
            x: source.x,
            y: source.y
          };
          return diagonal({
            source: o,
            target: o
          });
        })
        .remove();

      // stash the old positions for transition.
      nodes.forEach((d: any) => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    };

    // toggle children on click.
    click = (d: any) => {
      if(((window as any).d3.event as any).defaultPrevented) { return; } // click suppressed
      d = toggleChildren(d);
      update(d);
      centerNode(d);
    };

    // define the root
    root = sourceTree;
    root.x0 = viewerHeight / 2;
    root.y0 = 0;

    // layout the tree initially and center on the root node.
    update(root);
    centerNode(root, 100);
  }

  private checkAdminAudience(segment: any): boolean {
    return !this.isGroup && this.type !== 'look-alike' && !segment.is_total_audience &&
      (!segment.stop_domains || !segment.stop_domains.length) && segment.domains && segment.domains.length;
  }
}
