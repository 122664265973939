import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AudienceAnalyticsComponent } from './analytics/audience-analytics.component';
import { AudienceConstructorComponent } from './constructor/constructor.component';
import { AudienceEditComponent } from './edit/audience-edit.component';
import { UserJourneyComponent } from './journey/user-journey.component';
import {ClusterComponent} from './cluster/cluster.component';

const routes: Routes = [{
  path: '',
  children: [
    { path: 'constructor', component: AudienceConstructorComponent },
    { path: 'constructor/:segmentId/edit', component: AudienceConstructorComponent },
    { path: ':segmentId/analytics', component: AudienceAnalyticsComponent },
    { path: 'create/:pixelId', component: AudienceEditComponent },
    { path: ':segmentId/edit', component: AudienceEditComponent },
    { path: 'journey', component: UserJourneyComponent },
    { path: ':segmentId/predictive', component: ClusterComponent }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AudienceRoutingModule {}
