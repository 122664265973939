import { Component, OnInit, Inject, EventEmitter, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {AuthService} from '../../../core/services/auth.service';

@Component({
  templateUrl: './filter-size.modal.component.html',
  styleUrls: [ './filter-size.modal.component.scss' ]
})
export class ModalFilterSizeComponent implements OnInit, AfterViewInit {
  private sizeList: any[] = [];
  private affinityList: any[] = [];

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  public affinitySliderOptions: any;
  public uniqsSliderOptions: any;
  public treeOptions: any = {
    idField: 'id',
    displayField: 'segment_name',
    childrenField: 'nodes'
  };
  public treeData: any;
  public countList: number[] = [50, 100, 200];
  public count: any;
  public affinityLengthAll: any;
  public query: any;
  public filterError: any;
  public filterErrorAffinity: any;
  public minSize: any;
  public maxSize: any;
  public minAffinity: any;
  public maxAffinity: any;
  public affinity: any;
  public uniqsRange: any;
  public textLoc: any = {};

  constructor(public dialogRef: MatDialogRef<ModalFilterSizeComponent>,
              @Inject(MAT_DIALOG_DATA) public resolve: any,
              private authService: AuthService) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
  }

  ngOnInit(): void {
    this.treeData = this.resolve.intersections.tree;

    this.count = this.resolve.intersections.count;
    this.affinityLengthAll = this.resolve.intersections.affinity.length;
    this.query = '';
    this.filterError = false;

    this.minSize = this.resolve.intersections.filter.minSize;
    this.maxSize = this.resolve.intersections.filter.maxSize;
    this.minAffinity = Math.floor(this.resolve.intersections.filter.minAffinity);
    this.maxAffinity = Math.ceil(this.resolve.intersections.filter.maxAffinity);

    this.resolve.intersections.affinity.forEach((item: any) => {
      this.sizeList.push(item.intersectionSize);
      this.affinityList.push(Math.round(parseFloat(item.affinity)));
    });

    this.sizeList.push(0);
    this.sizeList.sort( this.sortList );
    this.affinityList.push(0);
    this.affinityList.sort( this.sortList );

    this.affinity = [this.getIndexByAffinity('min'), this.getIndexByAffinity('max')];
    this.uniqsRange = [this.getIndexBySize('min'), this.getIndexBySize('max')];

    this.uniqsSliderOptions = {
      floor: 0,
      ceil: this.affinityLengthAll,
      translate: (): string => {
        return '';
      }
    };

    this.affinitySliderOptions = {
      floor: 0,
      ceil: this.affinityLengthAll,
      translate: (): string => {
        return '';
      }
    };

    this.checkVisibleTreeData(this.treeData);
  }

  ngAfterViewInit(): void {
    setTimeout( () => this.manualRefresh.emit(), 500);
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public addFilter(): void {
    this.dialogRef.close({
      filter: {
        minSize: this.minSize,
        maxSize: this.maxSize,
        minAffinity: this.minAffinity,
        maxAffinity: this.maxAffinity
      },
      count: this.count
    });
  }

  public changeSize(type: any): void {
    if(this.minSize > this.maxSize) {
      this.filterError = true;
      return;
    }
    this.filterError = false;

    if(type === 'min') {
      this.uniqsRange[0] = this.getIndexBySize(type);
    } else {
      this.uniqsRange[1] = this.getIndexBySize(type);
    }

    this.checkVisibleTreeData(this.treeData);
    this.manualRefresh.emit();
  }

  public changeAffinity(type: any): void {
    if(this.minAffinity > this.maxAffinity) {
      this.filterErrorAffinity = true;
      return;
    }
    this.filterErrorAffinity = false;

    if(type === 'min') {
      this.affinity[0] = this.getIndexByAffinity(type);
    } else {
      this.affinity[1] = this.getIndexByAffinity(type);
    }

    this.checkVisibleTreeData(this.treeData);
    this.manualRefresh.emit();
  }

  public checkTotal(): any {
    return this.resolve.intersections.affinity.filter((item: any) => {
      return this.checkFilterAllow(item) && item.isShow;
    }).length;
  }

  public slideChangeMinSize(value: number): void {
    this.minSize = Math.floor(this.sizeList[value]);
    this.checkVisibleTreeData(this.treeData);
  }

  public slideChangeMaxSize(value: number): void {
    this.maxSize = Math.ceil(this.sizeList[value]);
    this.checkVisibleTreeData(this.treeData);
  }

  public slideChangeMinAffinity(value: number): void {
    this.minAffinity = Math.floor(this.affinityList[value]);
    this.checkVisibleTreeData(this.treeData);
  }

  public slideChangeMaxAffinity(value: number): void {
    this.maxAffinity = Math.ceil(this.affinityList[value]);
    this.checkVisibleTreeData(this.treeData);
  }

  public test(node: any): void {
    console.log(node);
  }

  public checkVisible(): void {
    this.checkVisibleTreeData(this.treeData);
  }

  public checkVisibleSearch(): void {
    this.checkVisibleTreeData(this.treeData);
  }

  public toggleAll(node: any, value: any): void {
    const data: any = node.data;
    if(!data.is_empty) { data.isShow = value; }

    node.expand();
    this.toggleNodeList(data.nodes, value);
  }

  public visible(item: any): any {
    return item.isVisibleTree;
  }

  private _visible(item: any): any {
    return (
      !(this.query && this.query.length > 0 && !this.searchSegment(item))
      && (this.checkFilterAllow(item) || ((!item.affinity || item.nodes.length) && item.nodes.some((node: any) => {
        return this._visible(node);
      }))));
  }

  private checkFilterAllow(item: any): any {
    return (item.affinity >= this.minAffinity && item.affinity <= this.maxAffinity
      && item.intersectionSize >= this.minSize && item.intersectionSize <= this.maxSize);
  }

  private searchSegment(segment: any): any {
    const query: any = this.query.toLowerCase();
    let result: any = segment.segment_name.toLowerCase().indexOf(query) !== -1;
    if(result || !segment.nodes) {
      return result;
    }
    result = segment.nodes.some((node: any) => {
      return this.searchSegment(node);
    });
    return result;
  }

  private checkVisibleTreeData(data: any): void {
    data.forEach((item: any) => {
      item.isFilterAllow = this.checkFilterAllow(item);
      item.isVisibleTree = this._visible(item);
      if(item.nodes.length) { this.checkVisibleTreeData(item.nodes); }
    });
  }

  private toggleNodeList(nodes: any, value: any): void {
    nodes.forEach((node: any) => {
      if(node.affinity) { node.isShow = value; }
      if(node.nodes.length) { this.toggleNodeList(node.nodes, value); }
    });
  }

  private getIndexBySize(type: any): any {
    let index: number = 0;

    if(type === 'min') {
      index = this.sizeList.findIndex((item: any) => {
        return item >= this.minSize;
      });
    } else {
      index = (this.maxSize >= this.sizeList[this.sizeList.length - 1])
        ? this.sizeList.length - 1 : this.sizeList.findIndex((item: any) => {
        return item > this.maxSize;
      }) - 1;
      index = (index < 0) ? 0 : index;
    }

    return index;
  }

  private getIndexByAffinity(type: any): number {
    let index: number = 0;

    if(type === 'min') {
      index = this.affinityList.findIndex((item: any) => {
        return item >= this.minAffinity;
      });
    } else {
      index = (this.maxAffinity >= this.affinityList[this.affinityList.length - 1])
        ? this.affinityList.length - 1 : this.affinityList.findIndex((item: any) => {
        return item > this.maxAffinity;
      }) - 1;
      index = (index < 0) ? 0 : index;
    }

    return index;
  }

  private sortList(a: number, b: number): number {
    if(a > b) { return 1; }
    if(b > a) { return -1; }
    return 0;
  }
}
