import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {forkJoin} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Audience} from '../../../app-js/audiences/shared/constructor.type';
import {AudiencesService} from '../../core/services/audiences.service';
import {AuthService} from '../../core/services/auth.service';
import {MarketplaceService} from '../../core/services/marketplace.service';
import {UserMarketplaceService} from '../../core/services/user-marketplace.service';
import {UtilsService} from '../../core/services/utils.service';
import {ConstService} from '../../shared/const.service';
import {Chart} from 'angular-highcharts';

import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material';
import {ModalCSVOrderComponent} from './csv-order.modal/csv-order.modal.component';
import {ModalFilterSizeComponent} from './filter-size.modal/filter-size.modal.component';
import {CategoryAnalytics} from '../../../app-js/audiences/shared/analytics.type';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'hyb-audience-analytics',
  templateUrl: './audience-analytics.component.html',
  styleUrls: ['./audience-analytics.component.scss']
})
export class AudienceAnalyticsComponent implements OnInit {

  private service: any = this.audiencesService;
  private allCSVdata: any = [];
  private citiesCSVData: any = [];
  private genderCSVData: any = [];
  private affinityChartData: any = [];
  private affinityCompereData: any = [];
  private affinityTreeData: any = [];
  private affintyFilter: any = {};
  private selectedAffinity: any = [];
  private affinityExtremeValue: any = {};
  private affinityCount: number = 50;
  private affinityCountShow: number = 50;
  private allAffinityMap: any = {};
  private ACCURACY: any = {
    99: 2.58,
    95: 1.96,
    90: 1.65
  };
  private ageCategories: string[] = ['18-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60+'];
  private educationCategories: string[] = ['school', 'college', 'high', 'phd', 'doctoral'];
  private incomeCategories: string[] = ['micro', 'small', 'average', 'bigger', 'large'];
  private populationCategories: string[] = ['< 100k', '100-400k', '400-700k', '> 700r'];
  private availableAudiences: any = [];

  public dateMin: any;
  public dateMax: any;
  public dateValue: any = {
    startDate: null,
    endDate: null
  };
  public noStats: boolean = false;
  public groupDesc: string = '1stParty';
  public mapValue: string = 'ru';
  public compareAudienceId: any = null;
  public searchData: any = [];
  public mode: string = 'audience';
  public path: any;
  public preloaderAudience: boolean = true;
  public preloaderIntersection: boolean = true;
  public preloaderAnalytic: boolean = true;
  public preloaderCompare: boolean = false;
  public audienceId: number;
  public audience: any = {};
  public compareAudience: any = null;
  public notFound: boolean = false;
  public analitics: any = {};
  public affinitySectionLoading: boolean = true;
  public volumeSectionLoading: boolean = true;
  public selectedAffinityCount: number = 0;
  public allAffinity: any = [];
  public mapData: any[] = [];
  public mapRussiaData: any[] = [];
  public mapConfig: any;
  public mapRussiaConfig: any;
  public genderChartConfig: any;
  public ageFemaleChartConfig: any;
  public ageMaleChartConfig: any;
  public educationChartConfig: any;
  public incomeChartConfig: any;
  public affinityChartConfig: any;
  public uniqsChartConfig: any;
  public citiesChartConfig: any;
  public populationChartConfig: any;
  public devicesChartConfig: any;
  public osChartConfig: any;
  public browsersChartConfig: any;
  public volumeChartConfig: any;
  public mapChart: any;
  public mapRussiaChart: any;
  public genderChart: any;
  public genderCompareChart: any;
  public ageFemaleChart: any;
  public ageMaleChart: any;
  public educationChart: any;
  public incomeChart: any;
  public affinityChart: any;
  public uniqsChart: any;
  public citiesChart: any;
  public citiesCompareChart: any;
  public populationChart: any;
  public populationCompareChart: any;
  public devicesChart: any;
  public osChart: any;
  public browsersChart: any;
  public volumeChart: any;
  public affinitySortBy: any;
  public sortList: any[] = [{label: 'Uniqs', value: 'intersectionSize'}, {label: 'Affinity', value: 'affinity'}];
  public textLoc: any = {};
  public langId: any = localStorage.getItem('langId') || 'ru';
  public datePickerLocale: any = {
    cancelLabel: 'Cancel',
    applyLabel: 'Okay',
    clearLabel: 'Clear',
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
  };

  constructor(private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private audiencesService: AudiencesService,
              private marketplaceService: MarketplaceService,
              private utilsService: UtilsService,
              private userMarketplaceService: UserMarketplaceService,
              private authService: AuthService) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
      this.langId = localStorage.getItem('langId') || 'ru';

      this.datePickerLocale.cancelLabel = text.cancel;
      this.datePickerLocale.applyLabel = text.applyLabel;
      this.datePickerLocale.clearLabel = text.clearLabel;
      this.datePickerLocale.customRangeLabel = text.customRangeLabel;
      this.datePickerLocale.daysOfWeek = moment.weekdaysMin();
      this.datePickerLocale.monthNames = moment.monthsShort();

      this.sortList[0].label = text.uniqs;
      this.sortList[1].label = text.affinity;
    });

  }

  ngOnInit(): void {
    this.affinitySortBy = this.sortList[0].value;

    if (this.router.url.includes('marketplace')) {
      this.mode = 'market';
      this.service = this.marketplaceService;
    }

    this.audienceId = Number(this.route.snapshot.paramMap.get('segmentId'));

    $.each((window as any).Highcharts.maps['custom/world'].features, (index: any, feature: any) => {
      this.mapData.push({
        key: feature.properties['hc-key'],
        value: 0
      });
    });
    $.each((window as any).Highcharts.maps['countries/ru/ru-all'].features, (index: any, feature: any) => {
      this.mapRussiaData.push({
        key: feature.properties['hc-key'],
        value: 0
      });
    });
    this.mapConfig = {
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'bottom'
      },
      colorAxis: {
        min: 0,
        stops: [
          [0, '#FFFFFF'],
          [0.001, '#EFEFFF'],
          [0.5, (window as any).Highcharts.getOptions().colors[0]],
          [1, ((window as any).Highcharts.Color((window as any).Highcharts.getOptions().colors[0]) as any).brighten(-0.5).get()]
        ]
      },
      chart: {
        map: 'custom/world'
      },
      credits: {
        enabled: false
      },
      mapNavigation: {
        enabled: false
      },
      title: {
        text: ''
      },
      plotOptions: {
        map: {
          states: {
            hover: {
              color: (window as any).Highcharts.getOptions().colors[2]
            }
          },
          joinBy: ['hc-key', 'key'],
          dataLabels: {
            enabled: false,
            formatter: function (): any {
              return (this.point.properties && this.point.properties['hc-a2']);
            }
          }
        }
      },
      series: [{
        data: this.mapData,
        name: 'Uniqs',
        allAreas: false
      }]
    };

    this.mapRussiaConfig = {
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'bottom'
      },
      colorAxis: {
        min: 0,
        stops: [
          [0, '#FFFFFF'],
          [0.001, '#EFEFFF'],
          [0.5, (window as any).Highcharts.getOptions().colors[0]],
          [1, ((window as any).Highcharts.Color((window as any).Highcharts.getOptions().colors[0]) as any).brighten(-0.5).get()]
        ]
      },
      chart: {
        map: 'countries/ru/ru-all'
      },
      credits: {
        enabled: false
      },
      mapNavigation: {
        enabled: false
      },
      title: {
        text: ''
      },
      plotOptions: {
        map: {
          states: {
            hover: {
              color: (window as any).Highcharts.getOptions().colors[2]
            }
          },
          joinBy: ['hc-key', 'key'],
          dataLabels: {
            enabled: false,
            formatter: function (): any {
              return (this.point.properties && this.point.properties['hc-a2']);
            }
          }
        }
      },
      series: [{
        data: this.mapRussiaData,
        name: 'Uniqs',
        allAreas: false
      }]
    };

    this.service.getAudienceById(this.audienceId)
      .pipe(finalize(() => this.preloaderAudience = false))
      .subscribe((response: any) => {
        this.audience = response;
        this.getPath(this.audience, (path: string) => {
          this.path = path;
          this.createAudienceData(this.audienceId);
        });
      }, () => {
        this.notFound = true;
      });

    this.mapConfig.series[0].allAreas = true;
    this.mapRussiaConfig.series[0].allAreas = true;
    this.genderChartConfig = this.getChartConfig({title: 'Gender', type: 'pie'});
    this.ageFemaleChartConfig = this.getChartConfig({
      title: 'Females',
      type: 'bar',
      opposite: true,
      legend: false,
      categories: this.ageCategories,
      color: ConstService.COLORS.RED
    });
    this.ageMaleChartConfig = this.getChartConfig({
      title: 'Males',
      type: 'bar',
      legend: false,
      categories: this.ageCategories,
      color: ConstService.COLORS.BLUE
    });
    this.educationChartConfig = this.getChartConfig({
      title: 'Education',
      type: 'column',
      legend: false,
      categories: this.educationCategories,
      color: ConstService.COLORS.GREEN
    });
    this.incomeChartConfig = this.getChartConfig({
      title: 'Income',
      type: 'column',
      legend: false,
      categories: this.incomeCategories,
      color: ConstService.COLORS.ORANGE
    });
    this.affinityChartConfig = this.getChartConfig({
      title: 'Affinity',
      type: 'bar',
      legend: false,
      xAxisVisible: false,
      color: ConstService.COLORS.RED,
      events: {
        render: () => {
          this.affinitySectionLoading = false;
        }
      }
    });
    this.uniqsChartConfig = this.getChartConfig({
      title: 'Uniqs',
      type: 'bar',
      legend: false,
      xAxisVisible: true,
      color: ConstService.COLORS.RED,
      events: {
        render: () => {
          this.affinitySectionLoading = false;
        }
      }
    });
    this.citiesChartConfig = this.getChartConfig({
      title: 'Cities',
      type: 'column',
      legend: false,
      xAxisVisible: true,
      color: ConstService.COLORS.ORANGE,
      width: 531
    });
    this.populationChartConfig = this.getChartConfig({
      title: 'Population',
      type: 'pie',
      categories: this.populationCategories
    });

    this.devicesChartConfig = this.getChartConfig({
      title: 'Devices',
      type: 'pie',
      legend: true
    });
    this.osChartConfig = this.getChartConfig({
      title: 'OS',
      type: 'pie',
      legend: true
    });
    this.browsersChartConfig = this.getChartConfig({
      title: 'Browsers',
      type: 'pie',
      legend: true
    });

    this.volumeChartConfig = {
      title: {
        text: 'Volume'
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: true
      },
      options: {
        chart: {
          zoomType: 'x',
          type: 'line'
        },
        tooltip: {
          style: {
            padding: 10,
            fontWeight: 'bold'
          }
        }
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: [{
        labels: {
          style: {
            color: Highcharts.getOptions().colors[1]
          },
          format: '{value:,.0f}'
        },
        title: {
          text: 'Volume',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        opposite: false,
        visible: true
      }],
      series: [{name: 'Volume', data: []}],
      tooltip: {
        shared: true
      }
    };
  }

  public addAudience(): void {
    this.getAudienceDataForCompare(this.compareAudienceId);
  }

  public addSpaceToSearch(element: any): void {
    element.value = element.value + ' ';
  }

  /**
   * Get searching audience with limit 50
   * @param {string} search - search string
   */
  public getSearchData(search: string): void {
    const searchResultLimit: number = 50;
    this.searchData = [];
    const matcher: RegExp = new RegExp(search, 'i');
    let segment: Audience;
    for (let i: number = 0; i < this.availableAudiences.length && this.searchData.length < searchResultLimit; i++) {
      segment = this.availableAudiences[i];
      if (!search || (segment.segment_name.match(matcher) || (String(segment.segment_id).match(matcher)))) {
        this.searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          id: segment.id,
          size: segment.size,
          type: segment.type,
          isAudience: segment.isAudience
        });
      }
    }
  }

  public cleanIntersections(): void {
    this.affinityCount = 50;
    this.affinityCountShow = 50;

    this.marketplaceService.getAffinityDeleteProfile(this.audienceId).subscribe();

    this.setFilter('');
    this.setIntersections();

    this.selectedAffinityCount = this.selectedAffinity.length;
  }

  public exportAllCSV(): void {
    if (this.preloaderAnalytic || this.preloaderIntersection) {
      return;
    }

    const header_string: string = '"' + this.audience.segment_name + '", "' + this.audience.segment_id + '"\n\n';
    const config: MatDialogConfig<any> = new MatDialogConfig();

    const self: any = this;
    const dialogRef: MatDialogRef<ModalCSVOrderComponent> = this.dialog.open(ModalCSVOrderComponent, config);
    dialogRef.afterClosed().subscribe((data: any) => {
      if (!data) {
        return;
      }

      const order: any = data.split('-');
      const CSVData: any = (self[order[0]].concat(self[order[1]])).concat(self[order[2]]);
      this.utilsService.exportCSVTable(CSVData, this.audience.segment_id + '_' + this.audience.size + '_analytics', header_string);
    });
  }

  public exportFullIntersections(): void {
    const segment_id: number = this.audience.segment_id;
    const header_string: string = '"' + this.audience.segment_name + '", "' + segment_id + '"\n\n';
    const affinity: any = this.affinityToFormatCSV(this.allAffinity);

    this.utilsService.exportCSVTable(affinity, segment_id + '_full_affinity', header_string);
  }

  public changeDate(event: any): void {
    if (!event.startDate || !event.endDate) {
      return;
    }
    this.volumeChartConfig.xAxis.min = Number(event.startDate.format('x'));
    this.volumeChartConfig.xAxis.max = Number(event.endDate.format('x'));
    this.createVolumeChart();
  }

  public dateClick(event: any): void {
    event.stopPropagation();
  }

  public openModalRange(): void {
    const config: MatDialogConfig<any> = new MatDialogConfig();
    config.data = {
      intersections: {
        affinity: this.affinityChartData,
        tree: this.affinityTreeData,
        filter: this.affintyFilter,
        count: this.affinityCount,
        extremeValue: this.affinityExtremeValue
      }
    };

    const dialogRef: MatDialogRef<ModalFilterSizeComponent> = this.dialog.open(ModalFilterSizeComponent, config);
    dialogRef.afterClosed().subscribe((data: any) => {
      if (!data) {
        return;
      }

      this.selectedAffinity = this.affinityChartData.filter((item: any) => item.isShow).map((item: any) => {
        return item.id;
      });

      this.selectedAffinityCount = this.selectedAffinity.length;
      this.affintyFilter = data.filter;
      this.affinityCount = data.count;
      this.affinityCountShow = data.count;

      this.marketplaceService.getAffinitySaveProfile(this.getSendData()).subscribe();

      this.setIntersections();
    });
  }

  public showMoreAffinity(): void {
    this.affinityCountShow += 20;
    this.setIntersections();
  }

  public checkShowMore(): boolean {
    return this.affinityCountShow < this.allAffinity.length;
  }

  public getToleranceInfo(chartConfig: any): string {
    let averageErrorPercent: any = chartConfig.series[0].averageErrorPercent;
    if (!isFinite(averageErrorPercent) || !chartConfig.series[0].accuracy) {
      return '';
    }
    averageErrorPercent = averageErrorPercent === 0 ? '<0.1%' : averageErrorPercent + '%';
    return `Tolerance
            sample: ${chartConfig.series[0].sampleSize}
            probability: ${chartConfig.series[0].accuracy}%
            deviation: ${averageErrorPercent}`;
  }

  public setIntersections(): void {
    const allAffinity: any = this.affinityChartData.filter((item: any) => {
      return item.affinity >= this.affintyFilter.minAffinity && item.affinity <= this.affintyFilter.maxAffinity
        && item.intersectionSize >= this.affintyFilter.minSize && item.intersectionSize <= this.affintyFilter.maxSize
        && item.isShow && item.group_desc === this.groupDesc;
    });
    allAffinity.sort((a, b) => b[this.affinitySortBy] - a[this.affinitySortBy]);

    const affinityTotal: number = allAffinity.length;
    const showCount: number = (affinityTotal > this.affinityCountShow) ? this.affinityCountShow : affinityTotal;
    const showItems: any[] = [];

    allAffinity.forEach((item: any, index: any) => {
      const name: string = allAffinity[index].segment_name;

      this.allAffinityMap[name] = index;

      if (index < showCount) {
        showItems.push(item);
      }
    });

    this.allAffinity = allAffinity;
    this.AddIntersectionsToFullCSV(allAffinity);
    this.showIntersections(showItems);
  }

  private getNormalizeSize(analytic: any): number {
    if (analytic.default_percent && analytic.provider_percent) {
      return Math.floor(analytic.size * analytic.default_percent / analytic.provider_percent);
    } else {
      return analytic.size;
    }
  }

  private getFakeSeriesData(config: any): void {
    if (config.title.text === 'Gender') {
      config.series[0].data[0] = {
        name: 'Male',
        size: 50,
        default_percent: 0.5,
        y: 50,
        color: ConstService.COLORS.BLUE
      };
      config.series[0].data[1] = {
        name: 'Female',
        size: 50,
        default_percent: 0.5,
        y: 50,
        color: ConstService.COLORS.RED
      };
      return;
    }
    if (config.title.text === 'Population') {
      config.series[0].data[0] = {
        name: '< 100k',
        size: 40,
        default_percent: 0.25,
        y: 50,
        color: ConstService.COLORS.ORANGE
      };
      config.series[0].data[1] = {
        name: '100-400k',
        size: 60,
        default_percent: 0.25,
        y: 60,
        color: ConstService.COLORS.BLUE
      };
      config.series[0].data[2] = {
        name: '400-700k',
        size: 70,
        default_percent: 0.25,
        y: 70,
        color: ConstService.COLORS.GREEN
      };
      config.series[0].data[3] = {
        name: '> 700k',
        size: 30,
        default_percent: 0.25,
        y: 30,
        color: ConstService.COLORS.RED
      };
      return;
    }
    if (config.title.text === 'Cities') {
      config.series[0].data = [{
        name: 'Moscow (RU)',
        y: 1000,
        size: 1000
      }, {
        name: 'St. Petersburg (RU)',
        y: 500
      }, {
        name: 'New York (US)',
        y: 300
      }, {
        name: 'Paris (FR)',
        y: 200
      }, {
        name: 'London (UK)',
        y: 100
      }];
      return;
    }
    config.series[0].data.forEach((data: any) => data.y = data.size = Math.random() * 100 / config.xAxis.categories.length);
  }

  private _cleanIntersections(): void {
    this.affinityChartConfig.series[0].data = [];
    this.affinityChartConfig.xAxis.categories = [];
    this.affinityChartConfig.xAxis.categories = [];
    this.uniqsChartConfig.series[0].data = [];
    this.uniqsChartConfig.xAxis.categories = [];
    this.uniqsChartConfig.xAxis.categories = [];
  }

  private AddIntersectionsToFullCSV(intersections: any): void {
    const formatted: any = this.affinityToFormatCSV(intersections);
    this.allCSVdata = formatted;
  }

  private affinityToFormatCSV(intersections: any): any {
    const formatted: any[] = [];

    formatted.push(['Affinity']);
    formatted.push(['segment name', 'uniqs', 'affinity']);

    intersections.forEach((item: any) => {
      formatted.push([item.segment_name, item.intersectionSize, item.affinity]);
    });

    formatted.push([]);
    return formatted;
  }

  /**
   * Get breakdown data for charts
   */
  private getBreakdownData(data: any): void {
    const breakdown: any = {
      device: {},
      os: {},
      browser: {}
    };
    data.forEach((row: CategoryAnalytics) => {
      if (breakdown.hasOwnProperty(row.group_name)) {
        breakdown[row.group_name][row.category_name] ?
          breakdown[row.group_name][row.category_name] += row.uniqs :
          breakdown[row.group_name][row.category_name] = row.uniqs;
      }
    });
    let breakdownConfigSeriesData: any = null;
    this.devicesChartConfig.series[0].data.length = 0;
    this.osChartConfig.series[0].data.length = 0;
    this.browsersChartConfig.series[0].data.length = 0;
    for (const group in breakdown) {
      if (breakdown.hasOwnProperty(group)) {
        switch (group) {
          case 'device':
            breakdownConfigSeriesData = this.devicesChartConfig.series[0].data;
            break;
          case 'os':
            breakdownConfigSeriesData = this.osChartConfig.series[0].data;
            break;
          case 'browser':
            breakdownConfigSeriesData = this.browsersChartConfig.series[0].data;
            break;
          default:
            console.warn('unknown analytics group: ' + group);
            breakdownConfigSeriesData = [];
            break;
        }
        const colors: any = Object.values(ConstService.COLORS);
        for (const category in breakdown[group]) {
          if (breakdown[group].hasOwnProperty(category)) {
            breakdownConfigSeriesData.push({
              name: category,
              y: breakdown[group][category]
            });
          }
        }
        breakdownConfigSeriesData.sort((a: any, b: any) => {
          return b.y - a.y;
        });
        breakdownConfigSeriesData.forEach((item: any, index: any) => {
          item.color = colors[index % colors.length];
        });
      }
    }
  }

  private getTopCities(cities: any): void {
    cities.sort((a: any, b: any) => {
      return b.size - a.size;
    });
    this.citiesCSVData.push(['Top Cities']);
    this.citiesCSVData.push(['city', 'country', 'value']);
    cities.forEach((top: any) => {
      this.citiesCSVData.push([top.city_name, top.country_iso, this.getNormalizeSize(top)]);
      const city_name: string = top.city_name + ' (' + top.country_iso + ')';
      this.citiesChartConfig.series[0].data.push({
        y: this.getNormalizeSize(top),
        name: city_name
      });
      this.citiesChartConfig.xAxis.categories.push(city_name);
    });
    this.citiesCSVData.push([]);
    this.citiesCSVData.push(['Countries']);
    this.citiesCSVData.push(['iso', 'country', 'uniqs']);
    this.mapData.forEach((country: any) => {
      this.citiesCSVData.push([country.key, ConstService.COUNTRIES[country.key.toUpperCase()], country.value]);
    });
    this.citiesCSVData.push([]);
  }

  private getAnalytics(analytics: any): void {
    analytics.forEach((item: any) => {
      let category: any;
      switch (item.group_name) {
        case 'gender':
          this.genderChartConfig.series[0].data.push({
            name: item.category_name,
            size: this.getNormalizeSize(item),
            default_percent: item.default_percent,
            y: this.getNormalizeSize(item),
            color: item.category_name.toLowerCase() === 'male' ? ConstService.COLORS.BLUE : ConstService.COLORS.RED
          });
          break;
        case 'education':
          switch (item.category_name) {
            case 'SECONDARY':
              category = this.educationChartConfig.series[0].data[0];
              category.name = 'school';
              break;
            case 'SECONDARY_SPECIAL':
              category = this.educationChartConfig.series[0].data[1];
              category.name = 'college';
              break;
            case 'HIGHER_1':
            case 'HIGHER_2':
              category = this.educationChartConfig.series[0].data[2];
              category.name = 'high';
              break;
            case 'PHD':
              category = this.educationChartConfig.series[0].data[3];
              category.name = 'phd';
              break;
            case 'DOCTORAL_DEGREE':
              category = this.educationChartConfig.series[0].data[4];
              category.name = 'doctoral';
              break;
          }
          if (!category) {
            break;
          }
          category.size += this.getNormalizeSize(item);
          !!item.default_percent && (category.default_percent += item.default_percent);
          category.y += this.getNormalizeSize(item);
          break;
        case 'income':
          category = this.incomeChartConfig.series[0];
          switch (item.category_name) {
            case 'micro':
              category = category.data[0];
              category.name = 'micro';
              break;
            case 'small':
              category = category.data[1];
              category.name = 'small';
              break;
            case 'average':
              category = category.data[2];
              category.name = 'average';
              break;
            case 'bigger':
              category = category.data[3];
              category.name = 'bigger';
              break;
            case 'large':
              category = category.data[4];
              category.name = 'large';
              break;
          }
          if (!category) {
            break;
          }
          category.size += this.getNormalizeSize(item);
          !!item.default_percent && (category.default_percent += item.default_percent);
          category.y += this.getNormalizeSize(item);
          break;
        case 'male_age':
        case 'female_age':
          category = item.group_name === 'male_age' ?
            this.ageMaleChartConfig.series[0] :
            this.ageFemaleChartConfig.series[0];
          switch (item.category_name.substring(2)) {
            case 'A17_21':
            case 'A22_24':
              category = category.data[0];
              category.name = '18-24';
              break;
            case 'A25_29':
              category = category.data[1];
              category.name = '25-29';
              break;
            case 'A30_34':
              category = category.data[2];
              category.name = '30-34';
              break;
            case 'A35_39':
              category = category.data[3];
              category.name = '35-39';
              break;
            case 'A40_44':
              category = category.data[4];
              category.name = '40-44';
              break;
            case 'A45_49':
              category = category.data[5];
              category.name = '45-49';
              break;
            case 'A50_54':
              category = category.data[6];
              category.name = '50-54';
              break;
            case 'A55_59':
              category = category.data[7];
              category.name = '55-59';
              break;
            case 'A60_64':
            case 'A65_00':
              category = category.data[8];
              category.name = '60+';
              break;
          }
          if (!category) {
            break;
          }
          category.size += this.getNormalizeSize(item);
          !!item.default_percent && (category.default_percent += item.default_percent);
          category.y += this.getNormalizeSize(item);
          break;
        case 'city_sizes':
          category = this.populationChartConfig.series[0];
          switch (item.category_name) {
            case 'small':
              category = category.data[0];
              category.name = '< 100k';
              category.color = ConstService.COLORS.ORANGE;
              break;
            case 'normal':
              category = category.data[1];
              category.name = '100-400k';
              category.color = ConstService.COLORS.BLUE;
              break;
            case 'big':
              category = category.data[2];
              category.name = '400-700k';
              category.color = ConstService.COLORS.GREEN;
              break;
            case 'large':
              category = category.data[3];
              category.name = '> 700k';
              category.color = ConstService.COLORS.RED;
              break;
          }
          if (!category) {
            break;
          }
          category.size = this.getNormalizeSize(item);
          category.default_percent = item.default_percent;
          category.y = this.getNormalizeSize(item);
          break;
        case 'country':
          this.setGeoData(item.category_name, this.getNormalizeSize(item));
          break;
        case 'country_region':
          this.setRussiaData(item.category_name, this.getNormalizeSize(item));
          break;
      }
    });
    this.genderChartConfig.series[0].data.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }

  private getPercentage(): void {
    const segmentSize: number = this.audience.size;
    const accuracy: string = '95';
    const z: any = this.ACCURACY[accuracy];
    [
      this.educationChartConfig,
      this.ageFemaleChartConfig,
      this.ageMaleChartConfig,
      this.incomeChartConfig,
      this.genderChartConfig,
      // this.citiesChartConfig,
      this.populationChartConfig
    ].forEach((config: any) => {
      const series: any = config.series[0];
      const sampleSize: number = series.data.reduce((x: any, item: any) => {
        return x + item.y;
      }, 0);
      series.sampleSize = series.data.reduce((x: any, item: any) => {
        return x + this.getNormalizeSize(item);
      }, 0);
      if (series.sampleSize < 10) {
        this.getFakeSeriesData(config);
      }
      series.data.forEach((item: any) => {
        item.percent = item.y / sampleSize * 100;
        item.y = item.percent;
      });
      series.accuracy = accuracy;
      series.averageErrorPercent = 0;
      series.data.forEach((item: any, index: any, arr: any) => {
        const defaultPercent: number = item.default_percent ? item.default_percent : 0.5;
        if (!!segmentSize) {
          item.error = z * Math.sqrt(defaultPercent * (1 - defaultPercent) * (segmentSize - series.sampleSize)
            / (series.sampleSize * segmentSize));
        } else {
          item.error = z * Math.sqrt(defaultPercent * (1 - defaultPercent) / series.sampleSize);
        }
        item.error_percent = Math.floor(item.error * 1000) / 10;
        series.averageErrorPercent += item.error_percent / arr.length;
      });
      series.averageErrorPercent = Math.floor(series.averageErrorPercent * 10) / 10;
    });
  }

  private getCSVdata(): void {
    [
      this.educationChartConfig,
      this.ageFemaleChartConfig,
      this.ageMaleChartConfig,
      this.incomeChartConfig,
      this.populationChartConfig
    ].forEach((config: any) => {
      this.genderCSVData.push([config.title.text]);
      for (let i: number = 0; i < config.xAxis.categories.length; i++) {
        this.genderCSVData.push([config.xAxis.categories[i], this.getNormalizeSize(config.series[0].data[i])]);
      }
      this.genderCSVData.push([]);
    });
    this.genderCSVData.push([this.genderChartConfig.title.text]);
    for (let i: number = 0; i < this.genderChartConfig.series[0].data.length; i++) {
      this.genderCSVData.push([this.genderChartConfig.series[0].data[i].name,
        this.getNormalizeSize(this.genderChartConfig.series[0].data[i])]);
    }
    this.genderCSVData.push([]);
  }

  private setGeoData(country: any, size: any): void {
    this.mapData.forEach((item: any) => {
      if (item.key === country.toLowerCase()) {
        item.value = size;
      }
    });
  }

  private setRussiaData(country: any, size: any): void {
    const regionKey: string = 'ru-' + country.toLowerCase();
    this.mapRussiaData.forEach((item: any) => {
      if (item.key === regionKey) {
        item.value = size;
      }
    });
  }

  private getPath(audience: any, callback: Function): void {
    let path: string = '';
    // this.citiesChartConfig.plotOptions.column.dataLabels.enabled = false;
    this.service.getAudienceList().then((audiences: any) => {
      path = audience.segment_name;
      let parent_segment_id: any = audience.parents[0];
      const segment: any = this.service.getAudience(audience.id);
      segment && (audience.size = segment.size);
      // this.citiesChartConfig.plotOptions.column.dataLabels.enabled = true;
      // this.citiesChart.ref.redraw();
      while (parent_segment_id) {
        let parent: any = null;
        for (let i: number = 0; i < audiences.length; i++) {
          if (parent_segment_id === audiences[i].segment_id) {
            parent = audiences[i];
            path = parent.segment_name + ' » ' + path;
            parent_segment_id = parent.parents[0];
            break;
          }
        }
        parent || (parent_segment_id = null);
      }
      callback(path);
    });
  }

  private joinResponseData(affinityList: any, response: any): void {
    this.affinityChartData = [];
    this.affinityTreeData = [];
    const marketplaceList: any = JSON.parse(JSON.stringify(response));

    affinityList = affinityList.filter((item: any) => {
      return item.name !== 'Regular users';
    });

    marketplaceList.forEach((segment: any) => {
      affinityList.forEach((item: any) => {
        if (item.id === segment.id) {
          segment.affinity = item.affinity;
          segment.intersectionSize = this.getNormalizeSize(item);
          segment.group_desc = item.group_desc;
        }
      });
      if (!segment.affinity) {
        segment.intersectionSize = 0;
      }

      segment.nodes = [];
      segment.isFilterAllow = true;
      segment.isVisibleTree = true;
      segment.isShow = 0;
    });

    marketplaceList.sort((a: any, b: any) => {
      return b.intersectionSize - a.intersectionSize;
    });

    marketplaceList.forEach((segment: any) => {
      if (!segment.parent) {
        this.affinityTreeData.push(segment);
      } else {
        marketplaceList.forEach((item: any) => {
          if (segment.parent === item.segment_id) {
            item.nodes.push(segment);
          }
        });
      }

      if (segment.affinity) {
        this.affinityChartData.push(segment);
      }
    });

    this.affinityTreeData = this.affinityTreeData.filter((item: any) => {
      return filterEmptyNodes(item);
    });

    function filterEmptyNodes(node: any): number {
      node.nodes = node.nodes.filter((item: any) => {
        return filterEmptyNodes(item);
      });
      return node.nodes.length || node.affinity;
    }
  }

  private setFilter(data: any): void {
    this.affinityExtremeValue.minSize = 0;
    this.affinityExtremeValue.minAffinity = 0;
    this.affinityExtremeValue.maxSize = (this.affinityChartData.length && this.affinityChartData[0].intersectionSize)
      ? this.affinityChartData[0].intersectionSize : 0;
    this.affinityExtremeValue.maxAffinity = 0;

    this.affinityChartData.forEach((item: any) => {
      this.affinityExtremeValue.maxAffinity = (item.affinity > this.affinityExtremeValue.maxAffinity)
        ? item.affinity : this.affinityExtremeValue.maxAffinity;
    });

    if (!data) {
      this.selectedAffinity = this.affinityChartData.map((item: any) => item.id);

      this.affintyFilter.minSize = 0;
      this.affintyFilter.minAffinity = 0;
      this.affintyFilter.maxSize = Math.ceil(this.affinityExtremeValue.maxSize);
      this.affintyFilter.maxAffinity = Math.ceil(this.affinityExtremeValue.maxAffinity);
    } else {
      this.selectedAffinity = data.selected_segments;

      this.affintyFilter.minSize = data.uniqs_min;
      this.affintyFilter.minAffinity = data.affinity_min;
      this.affintyFilter.maxSize = (data.uniqs_max === 0) ? Math.ceil(this.affinityExtremeValue.maxSize) : data.uniqs_max;
      this.affintyFilter.maxAffinity = (data.affinity_max === 0) ? Math.ceil(this.affinityExtremeValue.maxAffinity) : data.affinity_max;
    }

    this.affinityChartData.forEach((item: any) => {
      item.isShow = (this.selectedAffinity.indexOf(item.id) !== -1) ? 1 : 0;
    });
  }

  private getSendData(): any {
    const data: any = {};

    data.selected_segments = this.selectedAffinity;
    data.uniqs_min = this.affintyFilter.minSize;
    data.affinity_min = this.affintyFilter.minAffinity;
    data.uniqs_max = (this.affintyFilter.maxSize === Math.ceil(this.affinityExtremeValue.maxSize)) ? 0 : this.affintyFilter.maxSize;
    data.affinity_max = (this.affintyFilter.maxAffinity === Math.ceil(this.affinityExtremeValue.maxAffinity))
      ? 0 : this.affintyFilter.maxAffinity;

    data.name = this.audience.segment_name + '(' + this.audience.segment_id + ')';
    data.id = this.audienceId;
    data.account = this.authService.getSelectedAccount().account_id;

    return data;
  }

  private getChartConfig(opts: any): any {
    const title: string = opts.title || '';
    const type: string = opts.type || 'bar';
    const opposite: boolean = !!opts.opposite;
    const legend: boolean = (typeof opts.legend === 'boolean') ? opts.legend : true;
    const xAxisVisible: boolean = (typeof opts.xAxisVisible === 'boolean') ? opts.xAxisVisible : true;
    const categories: any = opts.categories || [];
    const color: string = opts.color || '';
    const width: any = opts.width || null;
    const height: any = opts.height || null;
    const events: any = opts.events || {};

    const config: any = {
      title: {
        text: title
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: type,
        width: width,
        height: height,
        events: events
      },
      tooltip: {
        enabled: false,
        pointFormat: '<b>{point.name}: {point.percentage:.1f}%</b>',
        style: {
          padding: 10
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [{
              text: 'Export to PNG',
              onclick: function (): void {
                this.exportChart();
              }
            }]
          }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: '#000000',
            allowOverlap: true,
            formatter: function (): string {
              /*const dataSum: number = this.series.data.reduce((x: any, item: any) => {
                return x + item.total;
              }, 0);
              const pcnt: number = (this.y / dataSum) * 100;*/
              return (window as any).Highcharts.numberFormat(this.y, 0, ',') + '%';
            }
          },
          events: events
        },
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
        column: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: '#000000',
            formatter: function (): string {
              /*const dataSum: number = this.series.data.reduce((x: any, item: any) => {
                return x + item.total;
              }, 0);
              const pcnt: number = (this.y / dataSum) * 100;*/
              return (window as any).Highcharts.numberFormat(this.y, 0, ',') + '%';
            }
          }
        },
        series: {
          // stacking: 'normal'
        }
      },
      legend: {
        enabled: legend
      }
    };
    config.xAxis = {
      categories: categories,
      opposite: opposite,
      title: {
        text: null
      },
      visible: xAxisVisible
    };
    config.yAxis = {
      visible: false,
      reversed: opposite
    };
    config.series = [{
      name: title,
      color: color,
      // colorByPoint: true,
      data: []
    }];

    for (let i: number = 0; i < categories.length; i++) {
      config.series[0].data.push({
        name: '',
        size: 0,
        default_percent: 0,
        y: 0
      });
    }
    const self: any = this;


    function getSVG(charts: any, options: any, callback: any): void {
      const svgArr: any[] = [];
      let top: number = 0,
        widthSvg: number = 0,
        i: number;
      const svgResult: Function = (svgres: any) => {
        // let svg = svgres.replace('<svg', '<g transform="translate(0,' + top + ')" ');
        let svg: string = svgres.replace('<svg', '<g transform="translate(' + widthSvg + ', 0)"');
        svg = svg.replace('</svg>', '</g>');
        top = Math.max(top, charts[i].chartHeight);
        widthSvg += charts[i].chartWidth;
        svgArr.push(svg);
        if (svgArr.length === charts.length) {
          callback('<svg height="' + top + '" width="' + widthSvg + '" version="1.1" xmlns="http://www.w3.org/2000/svg">'
            + svgArr.join('') + '</svg>');
        }
      };
      for (i = 0; i < charts.length; ++i) {
        charts[i].getSVGForLocalExport(options, {}, () => {
          console.log('Failed to get SVG');
        }, svgResult);
      }
    }

    function exportCharts(charts: any, ext_options: any): void {
      const default_options: any = (window as any).Highcharts.getOptions().exporting;
      (default_options as any).libURL = default_options.url;
      const options: any = Object.assign({}, default_options, ext_options);

      charts.forEach((chart: any) => {
        const sourceWidth: any = chart.options.exporting.sourceWidth;
        chart.options.exporting.sourceWidth = sourceWidth ? sourceWidth : chart.chartWidth;
      });

      getSVG(charts, options, function (svg: any): void {
        ((window as any).Highcharts as any).downloadSVGLocal(svg, options);
      });
    }

    function exportAgeGender(): void {
      const charts: any = Array.prototype.map.call(document.getElementsByClassName('analytics__age_gander_chart'),
        function (el: any): any {
          return (window as any).Highcharts.charts[el.getAttribute('data-highcharts-chart')];
        });
      exportCharts(charts, null);
    }

    switch (title) {
      case 'Gender':
        config.exporting.enabled = false;
        config.tooltip.pointFormat = '<b>{point.percentage:.1f}%</b>';
        config.tooltip.enabled = true;
        break;
      case 'Population':
        config.tooltip.pointFormat = '<b>{point.percentage:.1f}%</b>';
        config.tooltip.enabled = true;
        break;
      case 'Females':
        config.exporting.enabled = false;
        config.exporting.buttons.contextButton.menuItems[0].onclick = exportAgeGender;
        config.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        config.tooltip.enabled = true;
        break;
      case 'Males':
        config.exporting.buttons.contextButton.menuItems[0].onclick = exportAgeGender;
        config.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        config.tooltip.enabled = true;
        break;
      case 'Education':
        config.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        break;
      case 'Income':
        config.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        break;
      case 'Uniqs':
        config.tooltip.pointFormat = '<b>uniqs: {point.y}</b>';
        config.plotOptions.bar.dataLabels.enabled = false;
        config.tooltip.enabled = true;
        config.series[0].pointWidth = 20;
        // this.xAxis.width = 200;
        config.xAxis.labels = {
          style: {
            // "width": "300px",
            // "min-width": "300px",
            'font-size': '14px'
          }
          // useHTML : true
        };
        config.xAxis.plotLines = [];
        config.plotOptions.series = {
          cursor: 'pointer',
          point: {
            events: {
              mouseOver: function (): void {
                const index: number = this.series.index;
                const chart: any = self.affinityChart.ref;
                const category: any = this.category;
                chart.series[index].data.forEach((item: any, i: any) => {
                  if (item.category === category) {
                    item.setState('hover');
                    chart.tooltip.refresh(chart.series[index].data[i]);
                  }
                });
              },
              mouseOut: function (): void {
                const index: number = this.series.index;
                const chart: any = self.affinityChart.ref;
                const category: any = this.category;
                chart.series[index].data.forEach((item: any) => {
                  if (item.category === category) {
                    item.setState();
                    chart.tooltip.hide();
                  }
                });
              }
            }
          }
        };
        break;
      case 'Browsers':
      case 'OS':
      case 'Devices':
        // todo: (prokopenko) to match magic values
        config.tooltip.enabled = true;
        config.legend.floating = true;
        config.legend.layout = 'vertical';
        config.legend.y = 365;
        config.legend.verticalAlign = 'top';
        config.chart.marginBottom = 175;
        config.chart.height = 555;
        config.series = [{
          type: 'pie',
          innerSize: '30%',
          data: []
        }];
        break;
      case 'Affinity':
        config.tooltip.pointFormat = 'Affinity index: <b>{point.real}</b>';
        config.tooltip.enabled = true;
        config.plotOptions.bar.dataLabels.enabled = false;
        config.series[0].pointWidth = 20;
        config.yAxis.min = -100;
        config.yAxis.max = 100;
        config.xAxis.plotLines = [];
        config.plotOptions.series = {
          cursor: 'pointer',
          point: {
            events: {
              mouseOver: function (): void {
                const index: number = this.series.index;
                const chart: any = self.uniqsChart.ref;
                const category: any = this.category;
                chart.series[index].data.forEach((item: any, i: any) => {
                  if (item.category === category) {
                    item.setState('hover');
                    chart.tooltip.refresh(chart.series[index].data[i]);
                  }
                });
              },
              mouseOut: function (): void {
                const index: number = this.series.index;
                const chart: any = self.uniqsChart.ref;
                const category: any = this.category;
                chart.series[index].data.forEach((item: any) => {
                  if (item.category === category) {
                    item.setState();
                    chart.tooltip.hide();
                  }
                });
              }
            }
          }
        };
        break;
      case 'Cities':
        config.plotOptions.column.dataLabels.formatter = function (): string {
          const dataSum: number = self.audience.size;
          if (!dataSum) {
            return '';
          }
          const pcnt: number = (this.y / dataSum) * 100;
          return (window as any).Highcharts.numberFormat(pcnt, 0, ',') + '%';
        };
        break;
      default:
        config.tooltip.pointFormat = '<b>{point.name}: {point.percentage:.1f}%</b>';
        break;
    }
    return config;
  }

  private createPopulationCharts(): void {
    this.genderChartConfig.title.text = this.textLoc.gender;
    this.ageFemaleChartConfig.title.text = this.textLoc.females;
    this.ageMaleChartConfig.title.text = this.textLoc.males;
    this.educationChartConfig.title.text = this.textLoc.education;
    this.incomeChartConfig.title.text = this.textLoc.income;
    this.citiesChartConfig.title.text = this.textLoc.cities;
    this.populationChartConfig.title.text = this.textLoc.population;

    [this.genderChartConfig, this.ageFemaleChartConfig, this.ageMaleChartConfig, this.educationChartConfig,
      this.incomeChartConfig, this.citiesChartConfig, this.populationChartConfig].forEach((config) => {
      config.exporting.buttons.contextButton.menuItems[0].text = this.textLoc.exportToPNG;
    });

    this.genderChart = new Chart(this.genderChartConfig);
    this.ageFemaleChart = new Chart(this.ageFemaleChartConfig);
    this.ageMaleChart = new Chart(this.ageMaleChartConfig);
    this.educationChart = new Chart(this.educationChartConfig);
    this.incomeChart = new Chart(this.incomeChartConfig);
    this.citiesChart = new Chart(this.citiesChartConfig);
    this.populationChart = new Chart(this.populationChartConfig);
  }

  private createMapCharts(): void {
    this.mapRussiaChart = (window as any).Highcharts.mapChart('analytics__russia-chart', this.mapRussiaConfig);
    this.mapChart = (window as any).Highcharts.mapChart('analytics__mapchart', this.mapConfig);
  }

  private createBreakDownCharts(): void {
    this.devicesChartConfig.title.text = this.textLoc.devices;
    this.osChartConfig.title.text = this.textLoc.os;
    this.browsersChartConfig.title.text = this.textLoc.browsers;

    [this.devicesChartConfig, this.osChartConfig, this.browsersChartConfig].forEach((config) => {
      config.exporting.buttons.contextButton.menuItems[0].text = this.textLoc.exportToPNG;
    });

    this.devicesChart = new Chart(this.devicesChartConfig);
    this.osChart = new Chart(this.osChartConfig);
    this.browsersChart = new Chart(this.browsersChartConfig);
  }

  private createVolumeChart(): void {
    this.volumeChartConfig.title.text = this.textLoc.volume;
    // this.volumeChartConfig.exporting.buttons.contextButton.menuItems[0].text = this.textLoc.exportToPNG;

    this.volumeChart = new Chart(this.volumeChartConfig);
  }

  private createIntersectionChart(): void {
    this.affinityChartConfig.title.text = this.textLoc.affinity;
    this.uniqsChartConfig.title.text = this.textLoc.uniqs;

    [this.affinityChartConfig, this.uniqsChartConfig].forEach((config) => {
      config.exporting.buttons.contextButton.menuItems[0].text = this.textLoc.exportToPNG;
    });

    this.affinityChart = new Chart(this.affinityChartConfig);
    this.uniqsChart = new Chart(this.uniqsChartConfig);
  }

  private createAudienceData(id: number): void {
    forkJoin([
      this.service.getTopCities(id),
      this.service.getAnalytics(id),
    ]).pipe(finalize(() => this.preloaderAnalytic = false))
      .subscribe((responses: any[]) => {
        this.getTopCities(responses[0]);
        this.getAnalytics(responses[1]);
        this.getPercentage();
        this.getCSVdata();
        this.createPopulationCharts();
        this.createMapCharts();
      });

    if (this.mode === 'audience') {
      this.service.getBreakdownAnalytics(id).subscribe((response) => {
        this.getBreakdownData(response);
        this.createBreakDownCharts();
      });

      this.audiencesService.getSegmentStats(this.audienceId)
        .pipe(finalize(() => this.volumeSectionLoading = false))
        .subscribe((response) => {
          this.getStats(response);
          this.createVolumeChart();
        });
    }

    forkJoin([
      this.service.getIntersections(id, '10000'),
      this.userMarketplaceService.getAudienceList(),
      this.marketplaceService.getAffinityGetProfile(id)
    ]).pipe(finalize(() => this.preloaderIntersection = false))
      .subscribe((responses: any[]) => {
        this.joinResponseData(responses[0], responses[1]);
        this.filterAudienceList(responses[1]);
        this.setFilter(responses[2]);
        this.setIntersections();
        this.createIntersectionChart();
        this.getSearchData('');
        this.selectedAffinityCount = this.selectedAffinity.length;
      });
  }

  private getAudienceDataForCompare(id: number): any {
    const self: any = this;
    const topCitiesData: any = [];
    const topCitiesCategory: any = [];
    const genderData: any = [];
    const educationData: any = this.createSeriesDataArray(5);
    const incomeData: any = this.createSeriesDataArray(5);
    const maleData: any = this.createSeriesDataArray(9);
    const femaleData: any = this.createSeriesDataArray(9);
    const populationData: any = this.createSeriesDataArray(4);

    const segment: any = this.availableAudiences.find((item: any) => item.id === id);
    const service: any = segment.isAudience ? this.audiencesService : this.marketplaceService;

    this.preloaderCompare = true;

    forkJoin([
      service.getTopCities(id),
      service.getAnalytics(id),
      service.getIntersections(id, '10000'),
      this.userMarketplaceService.getAudienceList(),
      service.getAudienceById(id)
    ]).pipe(finalize(() => {
      this.preloaderCompare = false;
    })).subscribe((responses: any[]) => {
      this.compareAudience = responses[4];
      this.getPath(this.compareAudience, (path: string) => this.compareAudience.path = path);
      responses[0].sort((a: any, b: any) => {
        return b.size - a.size;
      });
      responses[0].forEach((top: any) => {
        const city_name: string = top.city_name + ' (' + top.country_iso + ')';
        topCitiesData.push({
          y: this.getNormalizeSize(top),
          name: city_name
        });
        topCitiesCategory.push(city_name);
      });

      responses[1].forEach((item: any) => {
        let category: any;
        switch (item.group_name) {
          case 'gender':
            genderData.push({
              name: item.category_name,
              size: this.getNormalizeSize(item),
              default_percent: item.default_percent,
              y: this.getNormalizeSize(item),
              color: item.category_name.toLowerCase() === 'male' ? ConstService.COLORS.BLUE : ConstService.COLORS.RED
            });
            break;
          case 'education':
            switch (item.category_name) {
              case 'SECONDARY':
                category = educationData[0];
                category.name = 'school';
                break;
              case 'SECONDARY_SPECIAL':
                category = educationData[1];
                category.name = 'college';
                break;
              case 'HIGHER_1':
              case 'HIGHER_2':
                category = educationData[2];
                category.name = 'high';
                break;
              case 'PHD':
                category = educationData[3];
                category.name = 'phd';
                break;
              case 'DOCTORAL_DEGREE':
                category = educationData[4];
                category.name = 'doctoral';
                break;
            }
            if (!category) {
              break;
            }
            category.size += this.getNormalizeSize(item);
            !!item.default_percent && (category.default_percent += item.default_percent);
            category.y += this.getNormalizeSize(item);
            break;
          case 'income':
            switch (item.category_name) {
              case 'micro':
                category = incomeData[0];
                category.name = 'micro';
                break;
              case 'small':
                category = incomeData[1];
                category.name = 'small';
                break;
              case 'average':
                category = incomeData[2];
                category.name = 'average';
                break;
              case 'bigger':
                category = incomeData[3];
                category.name = 'bigger';
                break;
              case 'large':
                category = incomeData[4];
                category.name = 'large';
                break;
            }
            if (!category) {
              break;
            }
            category.size += this.getNormalizeSize(item);
            !!item.default_percent && (category.default_percent += item.default_percent);
            category.y += this.getNormalizeSize(item);
            break;
          case 'male_age':
          case 'female_age':
            category = item.group_name === 'male_age' ? maleData : femaleData;
            switch (item.category_name.substring(2)) {
              case 'A17_21':
              case 'A22_24':
                category = category[0];
                category.name = '18-24';
                break;
              case 'A25_29':
                category = category[1];
                category.name = '25-29';
                break;
              case 'A30_34':
                category = category[2];
                category.name = '30-34';
                break;
              case 'A35_39':
                category = category[3];
                category.name = '35-39';
                break;
              case 'A40_44':
                category = category[4];
                category.name = '40-44';
                break;
              case 'A45_49':
                category = category[5];
                category.name = '45-49';
                break;
              case 'A50_54':
                category = category[6];
                category.name = '50-54';
                break;
              case 'A55_59':
                category = category[7];
                category.name = '55-59';
                break;
              case 'A60_64':
              case 'A65_00':
                category = category[8];
                category.name = '60+';
                break;
            }
            if (!category) {
              break;
            }
            category.size += this.getNormalizeSize(item);
            !!item.default_percent && (category.default_percent += item.default_percent);
            category.y += this.getNormalizeSize(item);
            break;
          case 'city_sizes':
            switch (item.category_name) {
              case 'small':
                category = populationData[0];
                category.name = '< 100k';
                category.color = ConstService.COLORS.ORANGE;
                break;
              case 'normal':
                category = populationData[1];
                category.name = '100-400k';
                category.color = ConstService.COLORS.BLUE;
                break;
              case 'big':
                category = populationData[2];
                category.name = '400-700k';
                category.color = ConstService.COLORS.GREEN;
                break;
              case 'large':
                category = populationData[3];
                category.name = '> 700k';
                category.color = ConstService.COLORS.RED;
                break;
            }
            if (!category) {
              break;
            }
            category.size += this.getNormalizeSize(item);
            !!item.default_percent && (category.default_percent += item.default_percent);
            category.y += this.getNormalizeSize(item);
            break;
        }
      });

      this.addSeriesInChart(this.ageFemaleChart, femaleData, '#C4583D');
      this.addSeriesInChart(this.ageMaleChart, maleData, '#5091B7');
      this.addSeriesInChart(this.educationChart, educationData, '#5D8D40');
      this.addSeriesInChart(this.incomeChart, incomeData, '#DE943E');

      const genderChartConfig: any = this.getChartConfig({
        title: 'Gender',
        type: 'column',
        legend: false,
        categories: ['female', 'male']
      });

      genderChartConfig.tooltip.enabled = false;

      genderData.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });

      const genderSeries: any = {data: genderData};
      this.setPercentage(genderSeries);
      genderSeries.data[0].color = '#C4583D';
      genderSeries.data[1].color = '#5091B7';

      genderChartConfig.series[0] = this.genderChart.options.series[0];
      genderChartConfig.series[1] = genderSeries;

      this.genderCompareChart = new Chart(genderChartConfig);

      const citiesSeries: any = {data: topCitiesData};
      const citiesCompareChartConfig: any = this.getChartConfig({
        title: 'Cities',
        type: 'column',
        legend: false,
        xAxisVisible: true,
        color: ConstService.COLORS.ORANGE,
        width: 531
      });
      citiesCompareChartConfig.plotOptions.column.dataLabels.formatter = function (): string {
        const dataSum: number = self.compareAudience.size;
        if (!dataSum) {
          return '';
        }
        const pcnt: number = (this.y / dataSum) * 100;
        return (window as any).Highcharts.numberFormat(pcnt, 0, ',') + '%';
      };
      citiesCompareChartConfig.series[0] = citiesSeries;
      citiesCompareChartConfig.xAxis.categories = topCitiesCategory;
      this.citiesCompareChart = new Chart(citiesCompareChartConfig);

      const populationSeries: any = {data: populationData};
      this.setPercentage(populationSeries);
      const populationCompareChartConfig: any = this.getChartConfig({
        title: 'Population',
        type: 'pie',
        categories: this.populationCategories
      });
      populationCompareChartConfig.series[0] = populationSeries;
      this.populationCompareChart = new Chart(populationCompareChartConfig);

      this.affinityCompereData = [];
      const marketplaceList: any = JSON.parse(JSON.stringify(responses[3]));
      const affinityList: any = responses[2].filter((item: any) => {
        return item.name !== 'Regular users';
      });

      marketplaceList.forEach((segment: any) => {
        affinityList.forEach((item: any) => {
          if (item.id === segment.id) {
            segment.affinity = item.affinity;
            segment.intersectionSize = this.getNormalizeSize(item);
          }
        });
        if (segment.affinity) {
          this.affinityCompereData.push(segment);
        }
      });

      this.setIntersections();
    });
  }

  private showIntersections(intersectionList: any): void {
    const max_affinity: number = intersectionList.reduce((max: any, a: any) => {
      return a.affinity > max ? a.affinity : max;
    }, 100) - 100;
    const min_affinity: number = Math.abs(intersectionList.reduce((min: any, a: any) => {
      return a.affinity < min ? a.affinity : min;
    }, 100) - 100);
    const self: any = this;

    this._cleanIntersections();

    const rowHeight: number = this.compareAudienceId ? 32 : 20;

    // todo: (prokopenko) magic numbers??
    this.affinityChartConfig.chart.height = rowHeight * intersectionList.length + 62;
    this.affinityChartConfig.chart.width = 400;
    this.uniqsChartConfig.chart.height = rowHeight * intersectionList.length + 62;
    this.uniqsChartConfig.chart.width = 600;

    if (this.compareAudienceId) {
      this.affinityChartConfig.series[1] = {
        data: [],
        pointWidth: 20,
        color: '#4C9B24'
      };
      this.affinityChartConfig.series[0].color = ConstService.COLORS.GREEN;
      this.uniqsChartConfig.series[1] = {
        data: [],
        color: '#E25126',
        pointWidth: 20
      };
      this.uniqsChartConfig.series[0].color = ConstService.COLORS.RED;

      this.affinityChartConfig.series[0].pointWidth = 20;
      this.uniqsChartConfig.series[0].pointWidth = 20;

      this.affinityChartConfig.legend.enabled = true;
      this.affinityChartConfig.legend.x = 0;
      this.affinityChartConfig.legend.y = 0;
      this.affinityChartConfig.legend.labelFormatter = function (): any {
        return this.index ? self.compareAudience.segment_name : self.audience.segment_name;
      };

      this.uniqsChartConfig.legend.enabled = true;
      this.uniqsChartConfig.legend.x = 0;
      this.uniqsChartConfig.legend.y = 0;
      this.uniqsChartConfig.legend.labelFormatter = function (): any {
        return this.index ? self.compareAudience.segment_name : self.audience.segment_name;
      };
    }

    intersectionList.forEach((item: any) => {
      const affinity: number = Math.floor(item.affinity);

      this.affinityChartConfig.series[0].data.push({
        y: affinity > 100 ?
          (affinity - 100) / max_affinity * 100 :
          (affinity - 100) / min_affinity * 100,
        real: affinity,
        color: affinity > 100 ? ConstService.COLORS.GREEN : ConstService.COLORS.ORANGE
      });
      this.affinityChartConfig.xAxis.categories.push(item.segment_name);
      this.uniqsChartConfig.series[0].data.push(item.intersectionSize);
      this.uniqsChartConfig.xAxis.categories.push(item.segment_name);

      if (this.compareAudienceId) {
        const compareItem: any = this.affinityCompereData.find((aff) => item.id === aff.id);
        if (compareItem) {
          const affinityCompare: number = Math.floor(compareItem.affinity);

          this.affinityChartConfig.series[1].data.push({
            y: affinityCompare > 100 ?
              (affinityCompare - 100) / max_affinity * 100 :
              (affinityCompare - 100) / min_affinity * 100,
            real: affinityCompare,
            color: affinityCompare > 100 ? '#4C9B24' : '#DE943E'
          });
          this.uniqsChartConfig.series[1].data.push(compareItem.intersectionSize);
        } else {
          this.affinityChartConfig.series[1].data.push({
            y: 0,
            real: 0,
            color: '#DE943E'
          });
          this.uniqsChartConfig.series[1].data.push(0);
        }
      }
    });

    this.createIntersectionChart();
  }

  private setPercentage(series: any, show: boolean = false): void {
    const segmentSize: number = this.audience.size;
    const accuracy: string = '95';
    const z: any = this.ACCURACY[accuracy];
    const sampleSize: number = series.data.reduce((x: any, item: any) => {
      if (show) {
        console.log(item.y);
      }
      return x + item.y;
    }, 0);
    if (show) {
      console.log(sampleSize);
    }
    series.sampleSize = series.data.reduce((x: any, item: any) => {
      return x + this.getNormalizeSize(item);
    }, 0);
    // if(series.sampleSize < 10) {
    // this.getFakeSeriesData(config);
    // }
    series.data.forEach((item: any) => {
      item.percent = item.y / sampleSize * 100;
      item.y = item.percent;
    });
    series.accuracy = accuracy;
    series.averageErrorPercent = 0;
    series.data.forEach((item: any, index: any, arr: any) => {
      const defaultPercent: number = item.default_percent ? item.default_percent : 0.5;

      if (!!segmentSize) {
        item.error = z * Math.sqrt(defaultPercent * (1 - defaultPercent) * (segmentSize - series.sampleSize)
          / (series.sampleSize * segmentSize));
      } else {
        item.error = z * Math.sqrt(defaultPercent * (1 - defaultPercent) / series.sampleSize);
      }
      item.error_percent = Math.floor(item.error * 1000) / 10;
      series.averageErrorPercent += item.error_percent / arr.length;
    });
    series.averageErrorPercent = Math.floor(series.averageErrorPercent * 10) / 10;
  }

  /**
   * Filter audience list to form available list for current constructor
   * @param {Audience} audienceList
   */
  private filterAudienceList(audienceList: Audience[]): void {
    audienceList.forEach((audience: Audience) => {
      if (audience.is_active && !audience.is_empty &&
        (['normal', 'look-alike', 'manual', 'analytics', 'constructor', 'url', 'geo'].indexOf(audience.type) !== -1)
        && !(this.audience.segment_id === audience.segment_id) && audience.size > 0) {
        this.availableAudiences.push(audience);
      }
    });
  }

  private createSeriesDataArray(length: number): any {
    const result: any = [];
    for (let i: number = 0; i < length; i++) {
      result.push({
        y: 0,
        size: 0,
        default_percent: 0
      });
    }
    return result;
  }

  private addSeriesInChart(chart: any, data: any, color: string): void {
    const series: any = {data: data};
    this.setPercentage(series);
    series.color = color;
    if (chart.ref.series.length > 1) {
      chart.ref.series[1].remove();
    }
    chart.ref.addSeries(series);
  }

  private getStats(response: any): void {
    if (response.length === 0) {
      this.noStats = true;
    } else {
      this.volumeChartConfig.series[0].data.length = 0;
      this.volumeChartConfig.series[0].name = this.audience.segment_name;
      response.forEach((stat: any) => {
        this.volumeChartConfig.series[0].data.push([new Date(stat.dt).getTime(), stat.size]);
      });
      const dateDefault: any = moment(this.volumeChartConfig.series[0].data.slice(-1)[0][0]).subtract(90, 'days');
      this.dateMin = moment(this.volumeChartConfig.series[0].data[0][0]);
      this.dateMax = moment(this.volumeChartConfig.series[0].data.slice(-1)[0][0]);
      this.dateValue.endDate = this.dateMax;

      if (this.dateMin.isSameOrBefore(dateDefault)) {
        this.volumeChartConfig.xAxis.min = Number(dateDefault.format('x'));
        this.dateValue.startDate = dateDefault;
      } else {
        this.dateValue.startDate = this.dateMin;
      }
    }
  }
}
