import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule, MatTooltipModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlHandlingStrategy, UrlTree } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { ChartModule } from 'angular-highcharts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AudienceModule } from './audience/audience.module';
import { CoreModule } from './core/core.module';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { EmptyComponent } from './shared/empty.component';
import { SharedModule } from './shared/shared.module';
import {AccountComponent} from './auth/account/account.component';

export class Ng1Ng2UrlHandlingStrategy implements UrlHandlingStrategy {

  shouldProcessUrl(url: UrlTree): boolean {
    const processedUrlList: string[] = [
      '/login',
      '/account',
      '/registration',
      '/email_confirm',
      '/error_confirm',
      '/reset_pass',
      '/pass_confirm',
      '/pixel/',
      '/audiences',
      '/sharing',
      '/marketplace',
      '/billing',
      '/campaigns',
      '/segments'
    ];
    return processedUrlList.some((processedUrl: string) => url.toString().startsWith(processedUrl));
  }

  extract(url: UrlTree): UrlTree { return url; }

  merge(newUrlPart: UrlTree, rawUrl: UrlTree): UrlTree { return newUrlPart; }
}

@NgModule({
  declarations: [
    AppComponent,
    EmptyComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    UpgradeModule,
    ChartModule,
    SharedModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatSelectModule,
    AudienceModule
  ],
  providers: [
    {provide: UrlHandlingStrategy, useClass: Ng1Ng2UrlHandlingStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
