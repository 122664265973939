import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { uiNotification } from 'angular';
import { forkJoin, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
// Services
import { UserMarketplaceService } from '../../core/services/user-marketplace.service';
import { AudiencesService } from '../../core/services/audiences.service';
import { AuthService } from '../../core/services/auth.service';
import { ConstructorService } from '../../core/services/constructor.service';
import { MarketplaceService } from '../../core/services/marketplace.service';
import { PixelService } from '../../core/services/pixel.service';
// Classes
import {
  AudienceSearch,
  ConstructorAudience,
  ConstructorAudienceRoot,
  ConstructorGroup,
  ConstructorGroupAnalytics,
  ConstructorIntersected,
  ConstructorRule,
  ConstructorRuleGroup,
  ConstructorRuleSegment,
  ConstructorRuleStats,
  ConstructorSendData,
  ConstructorType,
  ConstructorSendDataRule,
  Audience
} from '../../../app-js/audiences/shared/constructor.type';
import {Pixel, PixelStats} from '../../../app-js/pixels/shared/pixel.type';
import { GeoData, GeoFeatureClass } from '../../../app-js/audiences/shared/geodata.type';
import { CategoryAnalytics } from '../../../app-js/audiences/shared/analytics.type';
import {
  CapacityResponse,
  GeoDataResponse,
  SegmentCreateResponse
} from '../../../app-js/audiences/shared/response.type';
import { DjangoResponse } from '../../shared/types/django-response.type';
import { ErrorResponse } from '../../shared/types/error-response.type';

@Component({
  selector: 'hyb-audience-constructor',
  templateUrl: './constructor.component.html',
  styleUrls: ['./constructor.component.scss']
})
export class AudienceConstructorComponent implements OnInit, OnDestroy {

  private segmentId: number;
  private destroy$: Subject<any> = new Subject();

  public typeList: ConstructorType[] = ['Segment', 'Pixel'];
  public groupList: ConstructorGroup[] = [
    {name: 'Full', id: 'full'},
    {name: 'Device', id: 'devices'},
    {name: 'Operating System', id: 'operating_system'},
    {name: 'Browser', id: 'browsers'},
    {name: 'Geo', id: 'geo'},
    {name: 'Click', id: 'event_user_action_click'},
    {name: 'View', id: 'event_user_action_view'}];
  public pixelList: Pixel[];
  public pixelMap: ConstructorGroupAnalytics[] = [];
  public pixelCityMap: Array<GeoData[]> = [];
  public countryList: GeoData[];
  public audience: ConstructorAudienceRoot = {
    name: '',
    segment_id: null,
    id: null,
    intersected: [],
    excluded: [],
    size: 0,
    account: null
  };
  public constructorCapacity: number = 0;
  public capacityPreloader: boolean = false;
  public disableCreate: boolean = false;
  public maxIntersections: number = 10;
  public availableAudiences: Audience[] = [];
  public marketAudiencePreloader: boolean = true;
  public preloader: boolean = true;
  public modeEdit: boolean = false;
  public isRulesDisabled: boolean = false;
  public notFound: boolean = false;
  public searchData: AudienceSearch[];
  public expireDays: any = 30;
  public lifeTime: any = '-';
  public textLoc: any = {};
  public isUnlimTariff = true;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private userMarketplaceService: UserMarketplaceService,
              @Inject('Notification') private Notification: uiNotification.INotificationService,
              private audiencesService: AudiencesService,
              private constructorService: ConstructorService,
              private authService: AuthService,
              private marketplaceService: MarketplaceService,
              private pixelService: PixelService) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
  }

  ngOnInit(): void {
    this.segmentId = Number(this.route.snapshot.paramMap.get('segmentId'));
    if(this.segmentId) {
      this.initConstructorEdit();
    } else {
      this.initConstructorCreate();
    }
    this.authService.userTariff$.subscribe((tariff: any) => {
      if (tariff) {
        this.isUnlimTariff = tariff.type === 'unlimited';
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  /**
   * Get searching audience with limit 50 for ui-select
   * @param {string} search - search string
   */
  public getSearchData(search: string): AudienceSearch[] {
    const searchResultLimit: number = 50;
    this.searchData = [];
    if(!search) {
      this.constructorService.getAudienceList().forEach((audience: Audience) => {
        this.searchData.push({
          segment_name: audience.segment_name,
          segment_id: audience.segment_id,
          id: audience.id,
          size: audience.size,
          type: 'Segment'
        });
      });
    }
    if(this.searchData.length) {
      return this.searchData;
    }
    const matcher: RegExp = new RegExp(search, 'i');
    let segment: Audience;
    for(let i: number = 0; i < this.availableAudiences.length && this.searchData.length < searchResultLimit; i++) {
      segment = this.availableAudiences[i];
      if(segment.segment_name.match(matcher) || (String(segment.segment_id).match(matcher))) {
        this.searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          id: segment.id,
          size: segment.size,
          type: 'Segment'
        });
      }
    }

    return this.searchData;
  }

  public getSearchSegmentList(search: string, audience: ConstructorAudience): void {
    audience.searchSegmentData = this.getSearchData(search);
  }

  public clearSearch(event: any, audience: ConstructorAudience , el: any): void {
    if (event || audience.searchSegmentData.length) return;
    audience.searchSegmentData = this.getSearchData('');
    el.value = '';

    if (audience.id && !audience.searchSegmentData.find((item: AudienceSearch) => item.id === audience.id)) {
      audience.searchSegmentData.push({
        segment_name: audience.segment_name,
        segment_id: audience.segment_id,
        id: audience.id,
        size: audience.size,
        type: 'Segment'
      });
    }
  }

  /**
   * Copy data from audience list to ConstructorAudience(fix for ui-select)
   * @param {ConstructorAudience} audience
   */
  public selectCopy(audience: ConstructorAudience): void {
    const item: AudienceSearch = this.searchData.find((segment: AudienceSearch) => segment.id === audience.id);

    audience.segment_id = item.segment_id;
    audience.segment_name = item.segment_name;
    audience.id = item.id;
    audience.size = item.size;

    this.updateCapacity();
  }

  /**
   * Copy data from pixel list to ConstructorAudience(fix for ui-select) and check analytics for current pixel
   * @param {ConstructorAudience} pixel
   */
  public selectPixel(pixel: ConstructorAudience): void {
    const item: Pixel = this.pixelList.find((pixelItem) => pixelItem.id === pixel.pixel_id);

    pixel.pixel_description = item.description;
    pixel.pid = item.pid;
    pixel.total_audience_exists = item.exists_total_audience;

    if(pixel.total_audience_exists && pixel.group === 'full') {
      pixel.group = 'geo';
    }

    if(!this.pixelMap[item.id]) {
      this.createPixelMap(item.id);

      if(!item.collect_analytics) {
        return;
      }

      this.getPixelAnalytics(item.id, pixel, null);
    }

    this.updateCapacity();
  }

  /**
   * Set group default params for ConstructorAudience
   * @param {ConstructorAudience} pixel
   */
  public selectGroup(pixel: ConstructorAudience): void {
    if(pixel.group === 'full') {
      return;
    }
    if(pixel.group === 'geo') {
      const countryRus: GeoData = this.countryList.find((country: GeoData) => country.country_code === 'RU');
      pixel.country = countryRus.geoname_id;
      this.selectCountry(pixel);
      return;
    }
    if(this.pixelMap[pixel.pixel_id][pixel.group].length) {
      pixel.category = this.pixelMap[pixel.pixel_id][pixel.group][0].category_id;
    }
  }

  /**
   * Get city list for country
   * @param {ConstructorAudience} pixel
   */
  public selectCountry(pixel: ConstructorAudience): void {
    const item: GeoData = this.countryList.find((country: GeoData) => country.geoname_id === pixel.country);

    this.pixelCityMap[pixel.pixel_id] = [];
    pixel.city_preloader = true;
    pixel.city_disabled = false;

    this.constructorService.getGeodata({code: item.country_code, feature_class: GeoFeatureClass.City})
      .pipe(finalize(() => {
        pixel.city_preloader = false;
      }))
      .subscribe((response: GeoDataResponse) => {
        this.pixelCityMap[pixel.pixel_id].push({
          geoname_id: -1,
          name: 'All',
          country_code: '',
          feature_class: GeoFeatureClass.City,
          name_ru: 'All',
          population: 0
        });

        if(response.results.length) {
          response.results.forEach((city: GeoData) => {
            this.pixelCityMap[pixel.pixel_id].push(city);
          });
        } else {
          pixel.city_disabled = true;
        }

        pixel.city = this.pixelCityMap[pixel.pixel_id][0].geoname_id;
      });
  }

  /**
   * Add new intersected rules in constructor
   * @param {boolean} group - intersected group
   */
  public addIntersection(group: boolean): void {
    if(this.audience.intersected.length >= this.maxIntersections || this.isRulesDisabled) {
      return;
    }
    const audienceList: ConstructorAudience[] =
      group ? [this.getConstructorAudienceObject(), this.getConstructorAudienceObject()]
        : [this.getConstructorAudienceObject()];
    this.audience.intersected.push({
      group: group,
      audienceList: audienceList
    });
  }

  /**
   * Add new audience rule in intersected
   * @param {ConstructorAudience[]} rule - intersected group
   */
  public addAudience(rule: ConstructorAudience[]): void {
    if(this.isRulesDisabled) {
      return;
    }
    rule.push(this.getConstructorAudienceObject());
  }

  /**
   * Remove audience rule from constructor
   * @param {ConstructorAudience} audience - audience rule
   */
  public removeAudience(audience: ConstructorAudience): void {
    if(this.isRulesDisabled) {
      return;
    }

    for(let i: number = 0; i < this.audience.excluded.length; i++) {
      const exclude: ConstructorAudience = this.audience.excluded[i];
      if(exclude === audience) {
        this.audience.excluded.splice(i, 1);
        this.updateCapacity();
        return;
      }
    }
    for(let i: number = 0; i < this.audience.intersected.length; i++) {
      const intersect: ConstructorAudience[] = this.audience.intersected[i].audienceList;
      for(let j: number = 0; j < intersect.length; j++) {
        if(intersect[j] === audience) {
          if(intersect.length === 1) {
            this.audience.intersected.splice(i, 1);
          } else {
            intersect.splice(j, 1);
          }
          this.updateCapacity();
          return;
        }
      }
    }
  }

  public addSpaceToSearch(element: any): void {
    element.value = element.value + ' ';
  }

  /**
   * Remove intersected group from constructor
   * @param {ConstructorIntersected} intersected - intersected group
   */
  public removeGroup(intersected: ConstructorIntersected): void {
    if(this.isRulesDisabled) {
      return;
    }

    for(let i: number = 0; i < this.audience.intersected.length; i++) {
      if(this.audience.intersected[i] === intersected) {
        this.audience.intersected.splice(i, 1);
        this.updateCapacity();
        return;
      }
    }
  }

  /**
   * Unlock edit functions
   */
  public editRules(): void {
    this.isRulesDisabled = false;
  }

  /**
   * Check edit permission
   * @return {boolean}
   */
  public haveEditPermission(): boolean {
    // TODO  move to service
    return (this.authService.getSelectedAccount() && (this.authService.getSelectedAccount().permission === 'edit' ||
      (this.authService.user.permissions.includes('partner.add_segmentconstructor') && !this.modeEdit)));
  }

  /**
   * Check valid constructor params for save function
   * @return {boolean}
   */
  public isFormValid(): boolean {
    if(this.disableCreate || !this.audience.name) {
      return false;
    }
    let notEmptyRulesCount: number = 0;
    this.audience.intersected.forEach((intersection: ConstructorIntersected) => {
      intersection.audienceList.forEach((segment: ConstructorAudience) => {
        if(segment.type === 'Segment') {
          segment.id && notEmptyRulesCount++;
        } else {
          if(segment.pixel_id && (segment.category || segment.group === 'full' || segment.country)) {
            notEmptyRulesCount++;
          }
        }
      });
    });
    if(notEmptyRulesCount > 1) {
      return true;
    } else if(notEmptyRulesCount === 0 || !this.audience.excluded.length) {
      return false;
    }
    this.audience.excluded.forEach((segment: ConstructorAudience) => {
      if(segment.type === 'Segment') {
        segment.id && notEmptyRulesCount++;
      } else {
        if(segment.pixel_id && (segment.category || segment.group === 'full' || segment.country)) {
          notEmptyRulesCount++;
        }
      }
    });
    return notEmptyRulesCount > 1;
  }

  /**
   * Create constructor
   */
  public createConstructor(): void {
    this.disableCreate = true;

    this.getSendData({
      segment_name: this.audience.name,
      expire_days: this.expireDays,
      account: this.authService.getSelectedAccount().account_id,
      segment_rule: {}
    }, (data: ConstructorSendData) => {
      if(!data) {
        this.disableCreate = false;
        return;
      }

      this.audiencesService.addAudienceConstructor(data)
        .pipe(finalize(() => {
          this.disableCreate = false;
        }))
        .subscribe((response: SegmentCreateResponse) => {
          this.audiencesService.addAudience(response);
          this.Notification.success(this.textLoc.constructorCreated);
          this.clearForm();
          this.router.navigate(['/audiences/constructor/' + response.id + '/edit']);
        }, (error: DjangoResponse<ErrorResponse>) => {
          this.Notification.error(this.getErrorMessage(error));
        });
    });
  }

  /**
   * Edit constructor
   */
  public editAudience(): void {
    this.disableCreate = true;

    this.getSendData({
      id: this.audience.id,
      segment_name: this.audience.name,
      account: this.authService.getSelectedAccount().account_id,
      segment_rule: {}
    }, (data: ConstructorSendData) => {
      if(!data) {
        this.disableCreate = false;
        return;
      }

      this.audiencesService.updateAudience(data)
        .pipe(finalize(() => {
          this.disableCreate = false;
        }))
        .subscribe(() => {
          this.audiencesService.getAudienceList().then(() => {
            this.audiencesService.getAudience(data.id).segment_name = data.segment_name;
          });
          this.Notification.success(this.textLoc.constructorEdited);
          this.setAudienceDisabled();
        }, (error: DjangoResponse<ErrorResponse>) => {
          this.Notification.error(this.getErrorMessage(error));
        });
    });
  }

  /**
   * Get searching city list from http request and add default param 'all'
   * @param {string} search - search string
   * @param {ConstructorAudience} pixel
   */
  public getSearchCity(search: string, pixel: ConstructorAudience): void {
    const code: string = this.countryList.find((country: GeoData) => {
      return country.geoname_id === pixel.country;
    }).country_code;

    this.constructorService.getGeodata({code: code, search: search, feature_class: GeoFeatureClass.City})
      .pipe(finalize(() => {
        pixel.city_preloader = false;
      }))
      .subscribe((response: GeoDataResponse) => {
        this.pixelCityMap[pixel.pixel_id] = [];
        this.pixelCityMap[pixel.pixel_id].push({
          geoname_id: -1,
          name: 'All',
          country_code: '',
          feature_class: GeoFeatureClass.City,
          name_ru: 'All',
          population: 0
        });

        response.results.forEach((city: GeoData) => {
          this.pixelCityMap[pixel.pixel_id].push(city);
        });
      });
  }

  /**
   * Get data for constructor
   */
  private initConstructorCreate(): void {
    forkJoin([
      this.userMarketplaceService.getAudienceList(),
      this.pixelService.getPixels(),
      this.constructorService.getGeodata({feature_class: GeoFeatureClass.Country})
    ]).pipe(finalize(() => {
      this.preloader = false;
      this.marketAudiencePreloader = false;
    })).subscribe((responses: [Audience[], Pixel[], GeoDataResponse]) => {
      this.filterAudienceList(responses[0]);
      this.addIntersection(false);
      this.marketAudiencePreloader = false;
      this.pixelList = responses[1];
      this.countryList = responses[2].results;
    }, () => this.notFound = true);
  }

  /**
   * Get data for constructor and forms constructor rules
   */
  private initConstructorEdit(): void {
    this.audience.id = this.segmentId;
    this.modeEdit = true;
    this.isRulesDisabled = true;

    forkJoin([
      this.audiencesService.getConstructorRules(this.audience.id),
      this.userMarketplaceService.getAudienceList(),
      this.pixelService.getPixels(),
      this.constructorService.getGeodata({feature_class: GeoFeatureClass.Country}),
      this.audiencesService.getAudienceById(this.audience.id)
    ]).pipe(finalize(() => {
      this.preloader = false;
      this.marketAudiencePreloader = false;
    })).subscribe((responses: [ConstructorRule, Audience[], Pixel[], GeoDataResponse, any]) => {
      const response: ConstructorRule = responses[0];

      this.filterAudienceList(responses[1]);
      this.pixelList = responses[2];
      this.countryList = responses[3].results;

      if(response.account !== this.authService.getSelectedAccount().account_id) {
        this.router.navigate(['/audiences']);
      }
      this.audience.name = response.segment_name;
      this.audience.segment_id = response.segment_id;
      this.audience.account = response.account;
      this.lifeTime = this.getLifeTimeDays(responses[4]);
      this.audience.size = response.stats.reduce((pv: ConstructorRuleStats, cv: ConstructorRuleStats) => {
        return moment(pv.dt) > moment(cv.dt) ? pv : cv;
      }, {size: 0, dt: null}).size;
      if(this.audience.size) {
        this.constructorCapacity = this.audience.size;
        this.capacityPreloader = false;
      }
      const rule_groups: ConstructorRuleGroup = {
        exclude: [],
        intersected: []
      };
      response.constructor.forEach((rule: ConstructorRuleSegment) => {
        const audience: ConstructorAudience = {
          id: rule.segment_rule,
          segment_id: rule.segment_rule_id,
          segment_name: this.marketplaceService.getAudienceName(rule.segment_rule)
          || this.audiencesService.getAudienceName(rule.segment_rule) || '(hidden)',
          size: rule.segment_rule_size,
          type: 'Pixel',
          pixel_id: null,
          disabled: false,
          category: null,
          group: 'full',
          total_audience_exists: false,
          pid: '',
          pixel_description: '',
          pixel_preloader: false,
          city_preloader: false,
          country: null,
          city: null,
          city_disabled: false,
          searchSegmentData: this.getSearchData('')
        };

        if(rule.is_total_audience) {
          audience.pixel_id = rule.pixel_id;
          audience.disabled = true;
          this.createPixelMap(rule.pixel_id);
        } else if(rule.segment_rule_segment_category.length) {
          const category: number = rule.segment_rule_segment_category[0].category_id;
          audience.pixel_id = rule.pixel_id;
          audience.disabled = true;
          audience.category = category;

          this.createPixelMap(rule.pixel_id);
          this.getPixelAnalytics(rule.pixel_id, audience, category);
        } else if(rule.segment_rule_type === 'geo') {
          const geodata: GeoData = rule.segment_geodata[0];
          audience.pixel_id = rule.pixel_id;
          audience.disabled = true;
          audience.group = 'geo';
          audience.country = this.countryList.find((country: GeoData) => {
            return country.country_code === geodata.country_code;
          }).geoname_id;
          audience.city = (geodata.feature_class !== '0') ? geodata.geoname_id : -1;

          this.createPixelMap(rule.pixel_id);
          this.pixelCityMap[audience.pixel_id] = [{
            geoname_id: audience.city,
            name: (geodata.feature_class !== '0') ? geodata.name : 'All',
            country_code: geodata.country_code,
            feature_class: GeoFeatureClass.City,
            name_ru: (geodata.feature_class !== '0') ? geodata.name : 'All',
            population: 0
          }];
        } else {
          audience.type = 'Segment';

          if (!audience.searchSegmentData.find((item: AudienceSearch) => item.id === audience.id)) {
            audience.searchSegmentData.push({
              segment_name: audience.segment_name,
              segment_id: audience.segment_id,
              id: audience.id,
              size: audience.size,
              type: 'Segment'
            });
          }
        }

        if(rule.intersected_group === 'exclude') {
          rule_groups.exclude.push(audience);
        } else {
          rule_groups.intersected[rule.intersected_group]
            ? rule_groups.intersected[rule.intersected_group].push(audience)
            : rule_groups.intersected[rule.intersected_group] = [audience];
        }
      });

      if(rule_groups.exclude.length) {
        this.audience.excluded = rule_groups.exclude;
        delete rule_groups.exclude;
      }

      for(const key in rule_groups.intersected) {
        if(!rule_groups.intersected.hasOwnProperty(key)) {
          continue;
        }
        this.audience.intersected.push({
          group: (rule_groups.intersected[key].length > 1),
          audienceList: rule_groups.intersected[key]
        });
      }
      this.audience.size ? this.constructorCapacity = this.audience.size : this.updateCapacity();
    }, () => this.notFound = true);
  }

  /**
   * Forms request params for constructor
   * @param {ConstructorSendData} data - basic request params
   * @param {Function} callback
   * @return {ConstructorSendData} prepared request params
   */
  private getSendData(data: ConstructorSendData, callback: Function): ConstructorSendData {
    const pixelList: ConstructorAudience[] = [];

    // delete empty excluded rules
    if(this.audience.excluded.length) {
      const excluded_count: number = this.audience.excluded.length;
      for(let i: number = excluded_count - 1; i >= 0; i--) {
        if(this.audience.excluded[i].type === 'Segment') {
          !this.audience.excluded[i].id && this.audience.excluded.splice(i, 1);
        } else {
          if(!this.audience.excluded[i].pixel_id ||
            (!this.audience.excluded[i].category && this.audience.excluded[i].group !== 'full' && !this.audience.excluded[i].country)) {
            this.audience.excluded.splice(i, 1);
          }
        }
      }
      !this.audience.excluded.length && (this.audience.excluded.length = 0);
    }
    // delete empty intersected rules
    for(let j: number = this.audience.intersected.length - 1; j >= 0; j--) {
      const segments: ConstructorAudience[] = this.audience.intersected[j].audienceList;
      for(let i: number = segments.length - 1; i >= 0; i--) {
        if(segments[i].type === 'Segment') {
          !segments[i].id && segments.splice(i, 1);
        } else {
          if(!segments[i].pixel_id || (!segments[i].category && segments[i].group !== 'full' && !segments[i].country)) {
            segments.splice(i, 1);
          }
        }
      }
      !segments.length && this.audience.intersected.splice(j, 1);
    }

    if(!this.audience.intersected.length) {
      this.Notification.error(this.textLoc.ruleShouldInclude);
      this.audience.intersected.push({
        group: false,
        audienceList: [this.getConstructorAudienceObject()]
      });
      return;
    }

    this.audience.intersected.forEach((intersection: ConstructorIntersected, index: number) => {
      data.segment_rule[index] = [];
      intersection.audienceList.forEach((segment: ConstructorAudience) => {
        if(segment.type === 'Segment' || segment.disabled) {
          data.segment_rule[index].push(segment.id);
        } else {
          pixelList.push(segment);
        }
      });
    });

    if(this.audience.excluded.length) {
      data.segment_rule.exclude = [];
      this.audience.excluded.forEach((segment: ConstructorAudience) => {
        if(segment.type === 'Segment' || segment.disabled) {
          data.segment_rule.exclude.push(segment.id);
        } else {
          pixelList.push(segment);
        }
      });
    }

    if(pixelList.length) {
      const promiseList: Observable<SegmentCreateResponse>[] = [];

      pixelList.forEach((pixel: ConstructorAudience) => {
        promiseList.push(this.getSegmentObservable(pixel));
      });

      forkJoin(promiseList).subscribe((responses: any) => {
        responses.forEach((response: SegmentCreateResponse, i: number) => {
          this.audience.excluded.forEach((segment: ConstructorAudience) => {
            if(segment.type === 'Pixel' && segment === pixelList[i]) {
              data.segment_rule.exclude.push(response.id);
              segment.id = response.id;
            }
          });

          this.audience.intersected.forEach((intersection: ConstructorIntersected, index: number) => {
            intersection.audienceList.forEach((segment: ConstructorAudience) => {
              if(segment.type === 'Pixel' && segment === pixelList[i]) {
                data.segment_rule[index].push(response.id);
                segment.id = response.id;
              }
            });
          });
        });

        callback(data);
      }, (error: DjangoResponse<ErrorResponse>) => {
        callback(null);
        this.Notification.error(this.getErrorMessage(error));
      });
    } else {
      callback(data);
    }
  }

  /**
   * Filter audience list to form available list for current constructor
   * @param {Audience} audienceList
   */
  private filterAudienceList(audienceList: Audience[]): void {
    audienceList.forEach((audience: Audience) => {
      if(audience.is_active && !audience.is_empty &&
        (['normal', 'look-alike', 'manual', 'analytics', 'constructor', 'url', 'geo', 'context'].indexOf(audience.type) !== -1)
        && !(this.modeEdit && this.audience.segment_id === audience.segment_id)) {
        this.availableAudiences.push(audience);
      }
    });
  }

  /**
   * Get empty ConstructorAudience
   * @return {ConstructorAudience}
   */
  private getConstructorAudienceObject(): ConstructorAudience {
    return {
      type: 'Segment',
      group: 'full',
      total_audience_exists: false,
      category: null,
      pixel_id: null,
      pid: '',
      pixel_description: '',
      pixel_preloader: false,
      city_preloader: false,
      country: null,
      city: null,
      segment_name: '',
      segment_id: null,
      id: null,
      size: 0,
      disabled: false,
      city_disabled: false,
      searchSegmentData: this.getSearchData('')
    };
  }

  /**
   * Get analytics for pixel
   * @param {number} id - pixel id
   * @param {ConstructorAudience} pixel
   * @param {number} category_id - category id
   */
  private getPixelAnalytics(id: number, pixel: ConstructorAudience, category_id: number): void {
    pixel.pixel_preloader = true;

    forkJoin(
      this.pixelService.getPixelAnalyticsCommon({pixelId: id, noaggr: true}),
      this.pixelService.getPixelById(id)
    )
      .pipe(finalize(() => {
        pixel.pixel_preloader = false;
      }))
      .subscribe((response: any) => {
        const selectorNamesMap: any = {};
        const selectorIdMap: any = {};
        response[1].stats_settings.forEach((setting: PixelStats) => {
          if(['clicks_selector', 'views_selector'].indexOf(setting.type) !== -1) {
            selectorNamesMap[setting.value] = setting.description;
            selectorIdMap[setting.id] = setting.description;
          }
        });
        response[0].forEach((category: CategoryAnalytics) => {
          if(!this.pixelMap[id][category.group_name]) {
            return;
          }
          if (category.group_name === 'event_user_action_click' || category.group_name === 'event_user_action_view') {
            if(category.event_id) {
              category.category_name = selectorIdMap[category.event_id] || category.category_name;
            } else {
              category.category_name = selectorNamesMap[category.category_name] || category.category_name;
            }
          }
          this.pixelMap[id][category.group_name].push(category);

          if(category_id && category.category_id === category_id) {
            pixel.group = category.group_name;
          }
        });
      });
  }

  /**
   * Create pixel map for analytics list
   * @param {number} id - ConstructorAudience pixel id
   */
  private createPixelMap(id: number): void {
    this.pixelMap[id] = {
      devices: [],
      operating_system: [],
      browsers: [],
      event_user_action_click: [],
      event_user_action_view: []
    };
  }

  /**
   * Set disabled param for pixels when constructor edited
   */
  private setAudienceDisabled(): void {
    this.audience.excluded.forEach((audience: ConstructorAudience) => {
      if(audience.type === 'Pixel') {
        audience.disabled = true;
      }
    });

    this.audience.intersected.forEach((item: ConstructorIntersected) => {
      item.audienceList.forEach((audience: ConstructorAudience) => {
        if(audience.type === 'Pixel') {
          audience.disabled = true;
        }
      });
    });
  }

  /**
   * Set default params for constructor
   */
  private clearForm(): void {
    this.constructorCapacity = 0;
    this.audience = {
      name: '',
      intersected: [
        {
          group: false,
          audienceList: [this.getConstructorAudienceObject()]
        }
      ],
      excluded: [],
      segment_id: null,
      id: null,
      size: 0,
      account: null
    };
  }

  /**
   * Get constructor rules list if constructor does not have pixels
   * @return {ConstructorSendDataRule} rules list
   */
  private getConstructorRules(): ConstructorSendDataRule {
    const data: ConstructorSendDataRule = {};
    let hasPixel: boolean = false;

    this.audience.intersected.forEach((intersection: ConstructorIntersected, index: number) => {
      intersection.audienceList.forEach((segment: ConstructorAudience) => {
        if(segment.type === 'Pixel' && !segment.disabled) {
          hasPixel = true;
          return;
        }

        if(segment.segment_id) {
          data[index] || (data[index] = []);
          data[index].push(segment.segment_id);
        }
      });
    });

    if(this.audience.excluded.length) {
      this.audience.excluded.forEach((segment: ConstructorAudience) => {
        if(segment.type === 'Pixel' && !segment.disabled) {
          hasPixel = true;
          return;
        }

        if(segment.segment_id) {
          data.exclude || (data.exclude = []);
          data.exclude.push(segment.segment_id);
        }
      });
    }

    return (!hasPixel) ? data : null;
  }

  /**
   * Update constructor capacity if constructor rules does not have pixels
   */
  private updateCapacity(): void {
    const data: ConstructorSendDataRule = this.getConstructorRules();
    if(data === null) {
      this.constructorCapacity = -1;
      return;
    }

    let rulesCount: number = 0;
    for(const item in data) {
      if(data.hasOwnProperty(item)) {
        rulesCount += data[item].length;
      }
    }

    if(rulesCount < 2) {
      return;
    }
    this.capacityPreloader = true;
    this.marketplaceService.getConstructorCapacity(data).pipe(finalize(() => {
      this.capacityPreloader = false;
    })).subscribe((response: CapacityResponse) => {
      this.constructorCapacity = response.capacity;
    });
  }

  /**
   * Get http request observable for create segment with pixel params
   * @param {ConstructorAudience} pixel - pixel params
   * @return {Observable<SegmentCreateResponse>} http request observable
   */
  private getSegmentObservable(pixel: ConstructorAudience): Observable<SegmentCreateResponse> {
    if(pixel.group === 'full') {
      return this.audiencesService.saveAudience({
        pixel_pid: pixel.pid,
        segment_name: pixel.pid + ' ' + pixel.pixel_description,
        account: this.authService.getSelectedAccount().account_id,
        urls: [],
        keywords_shuffle: [],
        stop_domains: [],
        domains: [],
        partners_only: [pixel.pid],
        expired_max: 90,
        expired_min: 0,
        intensity_min: 1,
        is_total_audience: true,
        is_deleted: true
      });
    } else if(pixel.group === 'geo') {
      return this.pixelService.addSegmentGeo({
        account: this.authService.getSelectedAccount().account_id,
        pixelId: pixel.pixel_id,
        geoname_ids: (!pixel.city || pixel.city === -1) ? pixel.country : pixel.city
      });
    } else {
      return this.pixelService.addSegmentConstructorAnalytics({
        account: this.authService.getSelectedAccount().account_id,
        pixelId: pixel.pixel_id,
        analytics_category_id: pixel.category,
        segment_name: pixel.pid + ' ' + pixel.pixel_description,
        is_deleted: true
      });
    }
  }

  private getErrorMessage(error: any): string {
    let message: string = '';
    const errorResponse: any = error.error.data.error_message;
    if (Array.isArray(errorResponse)) {
      message = errorResponse[0];
      return message;
    }
    for(const item in errorResponse) {
      if(errorResponse.hasOwnProperty(item)) {
        message = errorResponse[item][0];
      }
    }
    return message;
  }

  private getLifeTimeDays(segment: any): any {
    if (!segment.expire_days || segment.expire_days < 0) {
      return '-';
    }
    const expireDate: any = new Date(segment.created_at);
    const todayDate: any = new Date();

    expireDate.setDate(expireDate.getDate() + segment.expire_days);

    const timeDiff: number = Math.abs(expireDate.getTime() - todayDate.getTime());
    if (timeDiff < 0) {
      return this.textLoc.expired;
    }

    return Math.ceil(timeDiff / (1000 * 3600 * 24)) + ' days';
  }
}

