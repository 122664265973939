import { AiButtonModule, AiInputModule } from '@aitarget/ai-library';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonToggleModule,
  MatMenuModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatProgressBarModule,
  MatDialogModule,
  MatCheckboxModule,
  MatIconModule,
  MatRadioModule
} from '@angular/material';

import { TreeModule } from 'angular-tree-component';
import { Ng5SliderModule } from 'ng5-slider';
import { PopoverModule } from 'ngx-popover';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as exporting from 'highcharts/modules/exporting.src';
import * as exportData from 'highcharts/modules/export-data.src';

import { SharedModule } from '../shared/shared.module';
import { AudienceAnalyticsComponent } from './analytics/audience-analytics.component';
import { ModalCSVOrderComponent } from './analytics/csv-order.modal/csv-order.modal.component';
import { ModalFilterSizeComponent } from './analytics/filter-size.modal/filter-size.modal.component';
import { AudienceRoutingModule } from './audience-routing.module';
import { AudienceConstructorComponent } from './constructor/constructor.component';
import { AudienceEditComponent } from './edit/audience-edit.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import {UserJourneyComponent} from './journey/user-journey.component';
import {SankeyDiagramComponent} from './journey/snakey-diagram/sankey-diagram.component';
import {ClusterComponent} from './cluster/cluster.component';
import {ModalSettingsComponent} from './journey/settings.modal/settings.modal.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    AudienceRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    AiButtonModule,
    AiInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    ChartModule,
    Ng5SliderModule,
    TreeModule.forRoot(),
    PopoverModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatMenuModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatDialogModule,
    MatCheckboxModule,
    MatIconModule,
    MatRadioModule,
    NgxDaterangepickerMd.forRoot()
  ],
  entryComponents: [
    AudienceAnalyticsComponent,
    ModalSettingsComponent,
    AudienceConstructorComponent,
    ModalFilterSizeComponent,
    ModalCSVOrderComponent,
    AudienceEditComponent,
    UserJourneyComponent,
    ClusterComponent
  ],
  declarations: [
    AudienceAnalyticsComponent,
    AudienceConstructorComponent,
    ModalSettingsComponent,
    ModalFilterSizeComponent,
    ModalCSVOrderComponent,
    AudienceEditComponent,
    UserJourneyComponent,
    SankeyDiagramComponent,
    ClusterComponent
  ],
  exports: [
    AudienceAnalyticsComponent
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ exporting, exportData ] }
  ]
})
export class AudienceModule {
}
