import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AudiencesService } from '../../core/services/audiences.service';
import {UserMarketplaceService} from '../../core/services/user-marketplace.service';
import {MarketplaceService} from '../../core/services/marketplace.service';
import * as moment from 'moment';

import {forkJoin} from 'rxjs';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {ModalSettingsComponent} from './settings.modal/settings.modal.component';
import {AuthService} from '../../core/services/auth.service';

@Component({
  selector: 'hyb-user-journey.component',
  templateUrl: './user-journey.component.html',
  styleUrls: ['./user-journey.component.scss']
})
export class UserJourneyComponent implements OnInit {
  private audienceList: any = [];
  private sourceList: any = [];
  private minUniqs: number = 20;
  private removeSegments: any = [];

  public links: Array<any> = [];
  public nodes: Array<any> = [];

  public fullList: Array<any> = [];
  public targetSegments: any = {
    forward: [],
    backward: []
  };
  public removedSegmentsList: any = {
    forward: [],
    backward: []
  };
  public sourceAudiences: any = [];
  public direction: string = 'forward';
  public errorNoDataAll: boolean = false;
  public errorWithRequest: boolean = false;
  public showChart: boolean = true;

  public journeyData: any = {
    forward: [],
    backward: []
  };
  public journeySearchData: any = [];
  public journeySearchRemove: any = [];
  public searchData: any[] = [];
  public preloader: boolean = true;
  public preloaderData: boolean = false;
  public preloaderJourney: boolean = false;

  public dateValue: any = {
    startDate: moment().subtract(2, 'week').startOf('isoWeek'),
    endDate: moment().subtract(2, 'week').endOf('isoWeek')
  };

  public ranges: any = {
    'Last week': [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')],
    'Two weeks ago': [moment().subtract(2, 'week').startOf('isoWeek'), moment().subtract(2, 'week').endOf('isoWeek')],
    'Three weeks ago': [moment().subtract(3, 'week').startOf('isoWeek'), moment().subtract(3, 'week').endOf('isoWeek')],
    'Four weeks ago': [moment().subtract(4, 'week').startOf('isoWeek'), moment().subtract(4, 'week').endOf('isoWeek')],
  };
  public textLoc: any = {};
  public datePickerLocale: any = {
    format: 'YYYY-MM-DD',
    cancelLabel: 'Cancel',
    applyLabel: 'Okay',
    clearLabel: 'Clear',
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
  };

  constructor(private route: ActivatedRoute,
              private dialog: MatDialog,
              private audiencesService: AudiencesService,
              private marketplaceService: MarketplaceService,
              private userMarketplaceService: UserMarketplaceService,
              private authService: AuthService,
              private cdr: ChangeDetectorRef) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;

      this.datePickerLocale.cancelLabel = text.cancel;
      this.datePickerLocale.applyLabel = text.applyLabel;
      this.datePickerLocale.clearLabel = text.clearLabel;
      this.datePickerLocale.customRangeLabel = text.customRangeLabel;
      this.datePickerLocale.daysOfWeek = moment.weekdaysMin();
      this.datePickerLocale.monthNames = moment.monthsShort();
    });
  }

  ngOnInit(): void {
    forkJoin([
      this.audiencesService.getAudienceList(),
      this.userMarketplaceService.getAudienceList(),
      this.marketplaceService.getUserJourneyGetProfile()
    ]).subscribe((responses: any[]) => {
      this.audienceList = responses[1];
      this.sourceList = responses[0].filter((segment) => segment.is_active && !segment.is_empty
        && (segment.type === 'normal' || segment.type === 'analytics'));
      this.getSearchData('');

      if (responses[2]) {
        this.minUniqs = responses[2].uniqs_min;
        this.removeSegments = responses[2].removed_segments;
      }

      this.preloader = false;
    }, () => {
      this.preloader = false;
    });
  }

  public changeDate(event: any): void {
    if (!event.startDate || !event.endDate) {
      return;
    }
    if (this.sourceAudiences.length) {
      this.resetSourceData();
    }
  }

  public changeDirection(event: any): void {
    if (this.sourceAudiences.length) {
      this.getJourneySearchData('');
      this.getJourneySearchRemove('');
    }
  }

  public openModalSettings(): void {
    const config: MatDialogConfig<any> = new MatDialogConfig();
    config.data = {
      uniqs_min: this.minUniqs,
      removed_segments: this.removeSegments,
      audienceList: this.sourceList
    };

    const dialogRef: MatDialogRef<ModalSettingsComponent> = this.dialog.open(ModalSettingsComponent, config);
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.marketplaceService.getUserJourneySaveProfile({
            account: this.authService.getSelectedAccount().account_id,
            name: this.authService.getSelectedAccount().account_id,
            ...data
        }).subscribe();
        this.minUniqs = data.uniqs_min;
        this.removeSegments = data.removed_segments;
        if (this.sourceAudiences.length) {
          this.resetSourceData();
        }
      }
    });
  }

  public deleteTargetSegment(index: number): void {
    this.targetSegments[this.direction].splice(index, 1);
    this.getJourneySearchData('');
  }

  public deleteRemoveSegments(index: number): void {
    this.removedSegmentsList[this.direction].splice(index, 1);
    this.getJourneySearchRemove('');
    this.showChart = false;
    this.cdr.detectChanges();
    this.showChart = true;
  }

  public deleteSourceSegment(index: number): void {
    this.sourceAudiences.splice(index, 1);
    this.getSearchData('');
    this.getJourneyData();
    this.getJourneySearchData('');
    this.getJourneySearchRemove('');
  }

  public selectSource(segment: any, search: any): void {
    const data: any = {
      account_ids: this.authService.getSelectedAccount().account_id,
      date_from: this.dateValue.startDate.format('YYYY-MM-DD'),
      date_to: this.dateValue.endDate.format('YYYY-MM-DD'),
      uniqs_min: this.minUniqs > 20 ? this.minUniqs : 20,
      removed_segments: this.removeSegments.join(',')
    };

    this.sourceAudiences.push(segment);
    this.getSearchData('');
    search.value = '';

    this.fullList.length = 0;
    this.errorNoDataAll = false;
    this.errorWithRequest = false;
    this.preloaderData = true;

    this.audiencesService.getUserJourney(segment.id, data).subscribe((response) => {
      this.sourceAudiences[this.sourceAudiences.length - 1].response = response;

      this.getJourneyData();
      this.getJourneySearchData('');
      this.getJourneySearchRemove('');
      this.preloaderData = false;
    }, () => {
      this.preloaderData = false;
      this.errorWithRequest = true;
    });
  }

  public selectTarget(event: any, search: any): void {
    this.targetSegments[this.direction].push(event.value);
    this.getJourneySearchData('');
    search.value = '';
  }

  public selectRemove(event: any, search: any): void {
    this.removedSegmentsList[this.direction].push(event.value);
    this.getJourneySearchRemove('');
    search.value = '';
    this.showChart = false;
    this.cdr.detectChanges();
    this.showChart = true;
  }

  public getSearchData(search: string): void {
    const search_results_limit: number = 50;
    const matcher: RegExp = new RegExp(search, 'i');
    this.searchData.length = 0;
    let segment: any;
    for(let i: number = 0; i < this.sourceList.length && this.searchData.length < search_results_limit; i++) {
      segment = this.sourceList[i];
      if((segment.segment_name.match(matcher) || String(segment.segment_id).match(matcher)) || !search) {
        let isSelected: boolean = false;
        for(let j: number = 0; j < this.sourceAudiences.length; j++) {
          if(this.sourceAudiences[j].segment_id === segment.segment_id) {
            isSelected = true;
            break;
          }
        }
        if(isSelected) {
          continue;
        }
        this.searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          index: i,
          id: segment.id,
          size: segment.size
        });
      }
    }
  }

  public getJourneySearchData(search: string): void {
    const matcher: RegExp = new RegExp(search, 'i');
    this.journeySearchData.length = 0;
    let segment: any;
    for(let i: number = 0; i < this.journeyData[this.direction].length; i++) {
      segment = this.journeyData[this.direction][i];
      if((segment.segment_name.match(matcher) || String(segment.segment_id).match(matcher)) || !search) {
        let isSelected: boolean = false;
        for(let j: number = 0; j < this.targetSegments[this.direction].length; j++) {
          if(this.targetSegments[this.direction][j].segment_id === segment.segment_id) {
            isSelected = true;
            break;
          }
        }
        if(isSelected) {
          continue;
        }
        this.journeySearchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          index: i,
          id: segment.id
        });
      }
    }
  }

  public getJourneySearchRemove(search: string): void {
    const matcher: RegExp = new RegExp(search, 'i');
    this.journeySearchRemove.length = 0;
    let segment: any;
    for(let i: number = 0; i < this.journeyData[this.direction].length; i++) {
      segment = this.journeyData[this.direction][i];
      if((segment.segment_name.match(matcher) || String(segment.segment_id).match(matcher)) || !search) {
        let isSelected: boolean = false;
        for(let j: number = 0; j < this.removedSegmentsList[this.direction].length; j++) {
          if(this.removedSegmentsList[this.direction][j].segment_id === segment.segment_id) {
            isSelected = true;
            break;
          }
        }
        if(isSelected) {
          continue;
        }
        this.journeySearchRemove.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          index: i,
          id: segment.id
        });
      }
    }
  }

  public addSpaceToSearch(element: any): void {
    element.value = element.value + ' ';
  }

  private resetSourceData(): void {
    const data: any = {
      account_ids: this.authService.getSelectedAccount().account_id,
      date_from: this.dateValue.startDate.format('YYYY-MM-DD'),
      date_to: this.dateValue.endDate.format('YYYY-MM-DD'),
      uniqs_min: this.minUniqs > 20 ? this.minUniqs : 20,
      removed_segments: this.removeSegments.join(',')
    };

    this.fullList.length = 0;
    this.errorNoDataAll = false;
    this.errorWithRequest = false;
    this.preloaderData = true;

    const observables: any = this.sourceAudiences.map((segment) => this.audiencesService.getUserJourney(segment.id, data));

    forkJoin(observables).subscribe((responses: any) => {
      responses.forEach((res, index) => {
        this.sourceAudiences[index].response = res;
      });

      this.getJourneyData();
      this.getJourneySearchData('');
      this.getJourneySearchRemove('');
      this.preloaderData = false;
    }, () => {
      this.errorWithRequest = true;
      this.preloaderData = false;
    });
  }

  private parseResponse(response: any): void {
    const nodes: any = [];
    const links: any = [];

    response.forEach((link) => {
      const sourceName: string = this.getSegmentNameByID(link[0]);
      const targetName: string = this.getSegmentNameByID(link[1]);

      const sourceIndex: number = nodes.find((item) => Number(item.id) === Number(link[0])) ?
        nodes.findIndex((item) => Number(item.id) === Number(link[0])) :
        nodes.push({name: sourceName, id: link[0]}) - 1;
      const targetIndex: number = nodes.find((item) => Number(item.id) === Number(link[1])) ?
        nodes.findIndex((item) => Number(item.id) === Number(link[1])) :
        nodes.push({name: targetName, id: link[1]}) - 1;

      const isDuplicate: boolean = links.find(l => l.source === sourceIndex && l.target === targetIndex
        || l.source === targetIndex && l.target === sourceIndex);

      if (!isDuplicate) {
        links.push({
          source: sourceIndex,
          target: targetIndex,
          value: link[2]
        });
      }
    });

    this.nodes = nodes;
    this.links = links;

    this.fullList = response;
  }

  private getSegmentNameByID(id: number): string {
    const segment: any = this.audienceList.find((seg: any) => Number(seg.segment_id) === Number(id));
    return segment ? segment.segment_name : id;
  }

  private getUserJourneyData(): void {
    const data: any = {
      account_ids: this.authService.getSelectedAccount().account_id,
      date_from: this.dateValue.startDate.format('YYYY-MM-DD'),
      date_to: this.dateValue.endDate.format('YYYY-MM-DD'),
      uniqs_min: this.minUniqs > 20 ? this.minUniqs : 20,
      removed_segments: this.removeSegments.join(',')
    };
    const paramNameTarget: string = this.direction === 'forward' ? 'targets_segments' : 'sources_segments';
    const paramNameSource: string = this.direction === 'forward' ? 'sources_segments' : 'targets_segments';

    const paramList: any = this.targetSegments[this.direction].length ? this.sourceAudiences.filter((segment) => {
        const targets: any = this.checkTargetSegments(segment);
        return targets.length;
      }).map((segment) => {
        const params: any = {...data};
        params[paramNameTarget] = this.checkTargetSegments(segment).map(seg => seg.segment_id).join(',');
        params[paramNameSource] = segment.segment_id;
        params.id = segment.id;
        return params;
      })
      : this.sourceAudiences.map((segment) => {
        const params: any = {...data};
        params.id = segment.id;
        return params;
      });

    this.fullList.length = 0;
    this.errorNoDataAll = false;
    this.errorWithRequest = false;
    this.preloaderJourney = true;

    this.reduceResponseData([], paramList);
  }

  private getJourneyData(): void {
    let forward: any = [];
    let backward: any = [];

    this.targetSegments.forward.length = 0;
    this.targetSegments.backward.length = 0;
    this.removedSegmentsList.forward.length = 0;
    this.removedSegmentsList.backward.length = 0;

    this.sourceAudiences.forEach((segment) => {
      forward = forward.concat(segment.response.forward);
      backward = backward.concat(segment.response.backward);
    });

    this.journeyData.forward = this.mapJourneyData(forward);
    this.journeyData.backward = this.mapJourneyData(backward);
  }

  private mapJourneyData(data: any): any {
    const sourceIds: number[] = this.sourceAudiences.map((segment) => Number(segment.segment_id));
    const journeyIds: number[] = [];
    const result: any[] = [];

    data.forEach((link) => {
      if (!journeyIds.includes(link[0]) && !sourceIds.includes(Number(link[0]))) {
        journeyIds.push(link[0]);
      }
      if (!journeyIds.includes(link[1]) && !sourceIds.includes(Number(link[1]))) {
        journeyIds.push(link[1]);
      }
    });

    journeyIds.forEach((id) => {
      const segment: any = this.audienceList.find((seg: any) => Number(seg.segment_id) === Number(id));
      if (segment) {
        result.push(segment);
      }
    });

    return result;
  }

  private checkTargetSegments(segment: any): any {
    if (!this.targetSegments[this.direction].length || !segment.response[this.direction].length) {
      return [];
    }
    const segmentIds: any = segment.response[this.direction].reduce(((acc, cur) => {
      if (!acc.includes(Number(cur[0]))) {
        acc.push(Number(cur[0]));
      }
      if (!acc.includes(Number(cur[1]))) {
        acc.push(Number(cur[1]));
      }
      return acc;
    }), []);
    return this.targetSegments[this.direction].filter((seg) => segmentIds.includes(Number(seg.segment_id)));
  }

  private reduceResponseData(data: any, paramList: any): void {
    const observables: any = paramList.map((param) => this.audiencesService.getUserJourney(param.id, param));
    forkJoin(observables).subscribe((responses: any) => {
      const direc: string = this.targetSegments[this.direction].length ? 'full' : this.direction;
      const responseLength: any = responses.map((res) => res[direc].length).reduce((acc, cur) => acc + cur, data.length);
      if (responseLength) {
        if (responseLength > 200) {
          const reduceParamList: any = [];
          responses.forEach((res, index) => {
            if (res[direc].length <= 40) {
              data = data.concat(res[direc]);
            } else {
              paramList[index].uniqs_min = res.min_weight_90;
              reduceParamList.push(paramList[index]);
            }
          });
          this.reduceResponseData(data, reduceParamList);
        } else {
          const responseData: any = responses.reduce((acc, cur) => acc.concat(cur[direc]), []);
          this.parseResponse(data.concat(responseData));
          this.preloaderJourney = false;
        }
      } else {
        if (!data.length) {
          this.errorNoDataAll = true;
        }
        this.preloaderJourney = false;
      }
    }, () => {
      this.errorWithRequest = true;
      this.preloaderJourney = false;
    });
  }
}
