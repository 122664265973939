import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import { AudiencesService } from '../../core/services/audiences.service';
import {UserMarketplaceService} from '../../core/services/user-marketplace.service';
import {AuthService} from '../../core/services/auth.service';
import {forkJoin} from 'rxjs';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {uiNotification} from 'angular';
import {DjangoResponse} from '../../shared/types/django-response.type';
import {ErrorResponse} from '../../shared/types/error-response.type';

@Component({
  selector: 'hyb-cluster.component',
  templateUrl: './cluster.component.html',
  styleUrls: ['./cluster.component.scss']
})
export class ClusterComponent implements OnInit {
  private segmentId: number;
  private audienceList: any = [];
  private form: FormArray;
  private clusterIdList: number[] = [];

  public audience: any;
  public preloader: boolean = true;
  public isEditMode: boolean = false;
  public sourceCount: number = 0;
  public textLoc: any = {};

  constructor(private route: ActivatedRoute,
              private router: Router,
              @Inject('Notification') private Notification: uiNotification.INotificationService,
              private audiencesService: AudiencesService,
              private userMarketplaceService: UserMarketplaceService,
              private authService: AuthService,
              private fb: FormBuilder) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
  }

  ngOnInit(): void {
    this.segmentId = Number(this.route.snapshot.paramMap.get('segmentId'));

    forkJoin([
      this.userMarketplaceService.getAudienceList(),
      this.audiencesService.getAudienceById(this.segmentId)
    ]).subscribe(([audienceList, audience]: [any, any]) => {
      this.audienceList = audienceList.filter((segment) => segment.is_active && !segment.is_empty &&
        (['normal', 'manual', 'analytics', 'constructor', 'url', 'geo', 'context'].indexOf(segment.type) !== -1) && this.segmentId !== segment.segment_id);
      this.audience = audience;

      this.getClusterList(audience.segment_id, audienceList);
      this.preloader = false;
    }, () => {
      this.preloader = false;
    });
  }

  public isFormValid(): boolean {
    return this.form.valid && this.sourceCount > 1;
  }

  public addSource(): void {
    this.form.push(this.getFormGroup());
    this.sourceCount = this.form.length;
  }

  public haveEditPermission(): boolean {
    return this.authService.getSelectedAccount() && (this.authService.getSelectedAccount().permission === 'edit');
  }

  public addSpaceToSearch(element: any): void {
    element.value = element.value + ' ';
  }

  public getSearchSegmentList(search: string, group: FormGroup): void {
    group.get('searchList').setValue(this.getSearchList(search));
  }

  public removeCluster(index: number): void {
    this.form.removeAt(index);
    this.sourceCount = this.form.length;
  }

  public createCluster(): void {
    const sourceSegmentsId: any = this.form.controls.map((group) => group.get('source').value);
    if (new Set(sourceSegmentsId).size !== sourceSegmentsId.length) {
      this.Notification.error(this.textLoc.pleaseChooseUniqueSegments);
      return;
    }
    const params: any = {
      account: this.authService.getSelectedAccount().account_id,
      source_segments: this.form.controls.filter((group: any) => !group.get('source').disabled)
        .map((group) => {
          return { segment_id: group.get('source').value,
                   exclude_segments: [] };
        })
    };
    this.audiencesService.addAudiencePredictive(params, this.segmentId).subscribe((response) => {
      response.forEach((audience) => {
        this.audiencesService.addAudience(audience);
      });
      this.Notification.success(this.textLoc.predictiveAudiencesCreated);
      this.router.navigate(['/audiences']);
    }, (error: DjangoResponse<ErrorResponse>) => {
      this.Notification.error(this.getErrorMessage(error));
    });
  }

  private getClusterList(parent: number, audienceList: any): void {
    const clusterList: any = audienceList.filter((segment: any) => segment.type === 'predictive' && segment.parents[0] === parent);
    this.clusterIdList = clusterList.map((item) => item.predictive_source_id);
    this.isEditMode = !!clusterList.length;

    this.createFormControls(clusterList);
  }

  private createFormControls(clusterList: any): void {
    this.form = this.fb.array([]);

    if (clusterList.length) {
      clusterList.forEach((segment) => {
        this.form.push(this.getFormGroup(segment));
      });
    } else {
      this.form.push(this.getFormGroup());
    }

    if (!clusterList.length || clusterList.length < 10) {
      this.form.push(this.getFormGroup());
    }

    this.sourceCount = this.form.length;
  }

  private getFormGroup(segment?: any): FormGroup {
    const group: FormGroup = this.fb.group({
      source: [{ value: (segment ? segment.predictive_source_id : ''), disabled: !!segment}, Validators.required],
      searchList: null
    });

    group.get('source').valueChanges.subscribe((value) => {
      this.clusterIdList = this.form.controls.map((item: any) => item.get('source').value);
    });

    const searchList: any = segment ? this.audienceList.filter((seg) => seg.segment_id === segment.predictive_source_id) : this.getSearchList('');
    group.get('searchList').setValue(searchList);

    return group;
  }

  private getSearchList(search: string): any {
    const searchResultLimit: number = 50;
    const searchData: any = [];
    const matcher: RegExp = new RegExp(search, 'i');

    let segment: any;
    for(let i: number = 0; i < this.audienceList.length && searchData.length < searchResultLimit; i++) {
      segment = this.audienceList[i];
      if (this.audience.segment_id === segment.segment_id || this.clusterIdList.includes(segment.segment_id)) {
        continue;
      }
      if(segment.segment_name.match(matcher) || (String(segment.segment_id).match(matcher))) {
        searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          id: segment.id,
          size: segment.size
        });
      }
    }

    return searchData;
  }

  private getErrorMessage(error: any): string {
    let message: string = '';
    const errorResponse: any = error.error.data.error_message;
    for(const item in errorResponse) {
      if(errorResponse.hasOwnProperty(item)) {
        message = errorResponse[item][0];
      }
    }
    return message;
  }
}
