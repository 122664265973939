import { Component } from '@angular/core';
import {AuthService} from '../../core/services/auth.service';

@Component({
  selector: 'hyb-preloader',
  templateUrl: './preloader.component.html'
})
export class PreloaderComponent {
  public textLoc: any = {};

  constructor(public authService: AuthService
  ) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
  }
}
