import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterPipe } from './pipes/filter.pipe';
import { PreloaderComponent } from './preloader/preloader.component';
import { NumberIsAvailablePipe } from './pipes/number-is-available.pipe';
import {AccountComponent} from '../auth/account/account.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatCheckboxModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule
} from '@angular/material';
import {AiButtonModule, AiInputModule} from '@aitarget/ai-library';
import {PopoverModule} from 'ngx-popover';
import {ModalDeletePlatformComponent} from '../auth/account/delete-platform.modal/delete-platform.modal.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDividerModule,
    MatSelectModule,
    MatInputModule,
    AiInputModule,
    PopoverModule,
    MatIconModule,
    MatDialogModule,
    AiButtonModule
  ],
  exports: [
    PreloaderComponent,
    FilterPipe,
    NumberIsAvailablePipe,
    AccountComponent
  ],
  declarations: [
    FilterPipe,
    PreloaderComponent,
    NumberIsAvailablePipe,
    AccountComponent,
    ModalDeletePlatformComponent
  ],
  entryComponents: [
    ModalDeletePlatformComponent
  ]
})
export class SharedModule {}
