import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import {AuthService} from '../../../core/services/auth.service';

@Component({
  templateUrl: './csv-order.modal.component.html'
})
export class ModalCSVOrderComponent {
  public order: string = 'genderCSVData-citiesCSVData-allCSVdata';
  public orderList: any = [
    { name: 'Demographic-Geography-Affinity', value: 'genderCSVData-citiesCSVData-allCSVdata' },
    { name: 'Demographic-Affinity-Geography', value: 'genderCSVData-allCSVdata-citiesCSVData' },
    { name: 'Geography-Demographic-Affinity', value: 'citiesCSVData-genderCSVData-allCSVdata' },
    { name: 'Geography-Affinity-Demographic', value: 'citiesCSVData-allCSVdata-genderCSVData' },
    { name: 'Affinity-Geography-Demographic', value: 'allCSVdata-citiesCSVData-genderCSVData' },
    { name: 'Affinity-Demographic-Geography', value: 'allCSVdata-genderCSVData-citiesCSVData' }
  ];
  public textLoc: any = {};

  constructor(public dialogRef: MatDialogRef<ModalCSVOrderComponent>,
              private authService: AuthService) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
      this.orderList[0].name = `${text.demographicBreakdown}-${text.geography}-${text.affinity}`;
      this.orderList[1].name = `${text.demographicBreakdown}-${text.affinity}-${text.geography}`;
      this.orderList[2].name = `${text.geography}-${text.demographicBreakdown}-${text.affinity}`;
      this.orderList[3].name = `${text.geography}-${text.affinity}-${text.demographicBreakdown}`;
      this.orderList[4].name = `${text.affinity}-${text.geography}-${text.demographicBreakdown}`;
      this.orderList[5].name = `${text.affinity}-${text.demographicBreakdown}-${text.geography}`;
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public download(): void {
    this.dialogRef.close( this.order );
  }
}
