import { Component } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { ConstService } from '../../shared/const.service';
import {NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'hyb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public tabs: string[] = ['pixels', 'audiences', 'sharing', 'marketplace', 'billing', 'segments'];
  public langId: any = localStorage.getItem('langId') || 'ru';
  public textLoc: any = {};
  public showHeader: any = true;
  public isUnlimTariff = true;

  constructor(public authService: AuthService,
              private router: Router
  ) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.showHeader = !event.url.startsWith('/email_confirm');
      }
    });
    this.authService.userTariff$.subscribe((tariff: any) => {
      if (tariff) {
        this.isUnlimTariff = tariff.type === 'unlimited';
      }
    });
  }

  public isImpersonate(): boolean {
    return this.authService.isImpersonate;
  }

  public stopImpersonating(redirect: boolean): void {
    this.authService.stopImpersonating(redirect);
  }

  // todo: (prokopenko) not best way. should use permission directive, but it work incorrect on impersonating
  public hasPermission(tab: string): boolean {
    if(tab in ConstService.PERMISSIONS) {
      return this.authService.hasPermission((ConstService.PERMISSIONS as any)[tab]);
    } else {
      return true;
    }
  }

  public logout(): void {
    this.authService.logout();
  }

  public getUser(): any {
    return this.authService.user;
  }

  public changeLang(langId: string): any {
    this.langId = langId;
    this.authService.changeLocale(langId);
  }
}
