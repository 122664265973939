import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyComponent } from './shared/empty.component';
import {AccountComponent} from './auth/account/account.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {path: 'audiences', loadChildren: './audience/audience.module#AudienceModule'},
  {path: 'pixel', redirectTo: '/pixel/list/', pathMatch: 'full'},
  {path: 'sharing', loadChildren: './sharing/sharing.module#SharingModule'},
  {path: 'marketplace', loadChildren: './marketplace/marketplace.module#MarketplaceModule'},
  {path: 'billing', component: EmptyComponent},
  {path: 'campaigns', component: EmptyComponent},
  {path: 'segments', component: EmptyComponent},
  {
    path: 'account', component: AccountComponent
  },
  // {path: '', redirectTo: '/app/account/list', pathMatch: 'full'},
  // {path: 'app', loadChildren: './layout/layout.module#LayoutModule'},
  // {path: 'extra', loadChildren: './extra-pages/extra-pages.module#ExtraPagesModule'},
  // {path: '**', redirectTo: '/extra/404', pathMatch: 'full'},
  {path: '**', component: EmptyComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
